const CustomersCtrl = ['$scope', '$filter', 'UserInfo', 'Task', 'Alert', 'filtersUtils',
    function ($scope, $filter, UserInfo, Task, Alert, filtersUtils) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.itemsToDownload = [];

        $scope.searchBy = 'id';
        $scope.searchByLabel = $translate('CUSTOMERS.NO_CLIENT');
        $scope.searchFilters = {
            'vat_id': $translate('CUSTOMERS.NIF/CIF'),
            'fullname': $translate('CUSTOMERS.CUSTOMER_FULLNAME'),
            'contract_id': $translate('CUSTOMERS.CONTRACT_NUM'),
            'id': $translate('CUSTOMERS.NO_CLIENT'),
            'search': $translate('CUSTOMERS.ALL')
        };
        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.agreements = [];
        $scope.results = [];

        $scope.ordering = {
            "id": $translate('CUSTOMERS.NO_CLIENT'),
            "name": $translate('CUSTOMERS.NAME'),
            "vat_id": $translate('CUSTOMERS.NIF/CIF'),
            "state": $translate('CUSTOMERS.STATE')
        };
        $scope.filters = {
            search:"",
            id: "",
            status: null,
            agreement: null,
            state: null,
            vat_id: "",
            contract_id:"",
            fullname: "",
            label: ""
        };

        $scope.update_list = {};

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$watch('searchQuery', function(newVal, oldVal){
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.canDownloadCustomers = function () {
            return UserInfo.hasPerm('customers.list_customers');
        };

        $scope.canAssignLabel = function () {
            return UserInfo.hasPerm('labels.add_labelledobject');
        };

        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        $scope.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        $scope.massive_labels_customers = function () {
            if ($scope.counter !== 0) {
                var task = new Task();
                task.type = 'LMAT';
                $scope.update_list.ids = $scope.getJSONParams($scope.itemsToDownload).ids;
                $scope.update_list.scope = 'customers'
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('LABELS.MASSIVE_LABELS'), true);
                    $scope.update_list.mode = ''
                    $scope.update_list.label = '';
                    $scope.update_list.comment_massive_label = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('LABELS.MASSIVE_LABELS_ERROR'), true);
                    $scope.update_list.mode = '';
                    $scope.update_list.label = '';
                    $scope.update_list.comment_massive_label = '';
                });
            }
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
    }
]

export { CustomersCtrl };
