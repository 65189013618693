{
  "LABELS": {
    "LABEL": "Label",
    "EXCLUDED_LABEL": "Excluded Label",
    "LABELS": "Labels",
    "SELECT_LABEL": "Select label",
    "CANCEL": "Cancel",
    "ASSIGN_LABELS": "Assign label",
    "MASSIVE_LABELS": "Massive labels",
    "ASSIGN_MASSIVE_LABELS": "Assign massive labels",
    "MASSIVE_LABELS_ERROR": "Error assign massive labels",
    "ADD": "Add",
    "REMOVE": "Remove"
  }
}
