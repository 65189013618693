function invoiceSetRemitDate(InvoiceSeries, UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/update-remit-date.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter, Task) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var api = API_URL + '/api/1.0/invoices/';
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.flagRemitDate = false;
            $scope.listToUpdate = [];
            $scope.remit_date = new Date();

            $scope.hasPerm = UserInfo.hasPerm('invoices.change_invoice');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/

            $scope.updateRemitDate = function () {
                if ($scope.counter > 0) {
                    var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                    var task = new Task();
                    task.type = 'ICHD';
                    task.params = {ids: ids, remit_date: DateUtils.transformToRESTyDate($scope.remit_date)};
                    task.start()
                        .then(function (data, status, headers, config) {
                            Alert.createAlert('success', $translate('INVOICES.CHANGE_REMIT_DATE_OK_TASK'), true);
                        })
                        .catch(function (data, status, headers, config) {
                            Alert.createAlert('danger', $translate('INVOICES.CHANGE_REMIT_DATE_OK_TASK_ERROR'), true);
                        });
                }
            };

            $scope.confirmRemitDate = function () {
                $scope.flagRemitDate = true;
            };

            $scope.updateNo = function () {
                $scope.flagRemitDate = false;
            };
        }
    }
}

export { invoiceSetRemitDate }
