const InvoicesCtrl = ['$scope', '$filter', '$window', '$cookies', 'UserInfo', 'Task', 'Alert', 'filtersUtils', 'INVOICES_TYPES', 'INVOICES_ORIGINS_TYPES', 'AgreementsMaster', 'DOMICILED_OPTIONS', 'InvoiceSeriesAPI', 'BrandsList',
    function ($scope, $filter, $window, $cookies, UserInfo, Task, Alert, filtersUtils, INVOICES_TYPES, INVOICES_ORIGINS_TYPES, AgreementsMaster, DOMICILED_OPTIONS, InvoiceSeriesAPI, BrandsList) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        $scope.INVOICES_OVERVIEW = require('../../templates/invoices-list/modals/invoices-overview.html')
        $scope.INVOICES_CUSTOM_PDFS = require('../../templates/invoices-list/modals/custom-pdfs.html')
        $scope.INVOICES_SET_CONTRACT_DATA = require('../../templates/invoices-list/modals/set-contract-data.html')
        $scope.INVOICES_LIST_RESULT = require('../../templates/invoice-list-result.html')
        $scope.INVOICES_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown-list.html')


        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.backgroundTasks = [];
        $scope.invoices_types = INVOICES_TYPES;
        $scope.invoices_various = INVOICES_ORIGINS_TYPES;
        $scope.domiciledOptions = DOMICILED_OPTIONS;

        $scope.header = {"cups": "CUPS"};

        $scope.searchFilters = {
            'invoice_id': $translate('INVOICES.NO_INVOICE'),
            'contract_idn': $translate('INVOICES.NO_CONTRACT'),
            'vat_id': $translate('INVOICES.NIF/CIF'),
            'cups': $translate('CONTRACTS.SUPPLY_CUPS')
        };

        $scope.draggableFields = {
            "pk": {
                "label": $translate('POWR.ID')
            },
            "idn__in":{
                "label": $translate('INVOICES.NO_INVOICE'),
                "pattern": "^\\w+$"
            },
            "contract__idn__in":{
                "label": $translate('CONTRACTS.CONTRACT_NUM'),
                "pattern": "^\\w+$"
            },
            "cups__in":{
                "label": $translate('CONTRACTS.SUPPLY_CUPS'),
                "pattern": "^\\w+$"
            },
        };

        $scope.update = {
            'face_compatible': true
        };
        $scope.update_list = {
            'set_remittable': false,
            'reason': ''
        };

        $scope.statuses = [
            {code: 'PAI', label: $translate('INVOICES.PAID')},
            {code: 'UNP', label: $translate('INVOICES.UNPAID')},
            {code: 'PEN', label: $translate('INVOICES.PENDING')},
            {code: 'DRF', label: $translate('INVOICES.DRAFT')},
            {code: 'INV', label: $translate('INVOICES.INVOICED')},
            {code: 'SNT', label: $translate('INVOICES.SENT')},
            {code: 'COP', label: $translate('INVOICES.COMPENSATED')},
            {code: 'CRC', label: $translate('INVOICES.CREDIT_RETURNED_CUSTOMER')}
        ];

        $scope.invoicing_modes = [
            {code: 'NOR', label: $translate('INVOICES.NORMAL_INVOICE')},
            {code: 'IND', label: $translate('INVOICES.INDEXED_INVOICE')},
            {code: 'INB', label: $translate('INVOICES.INDEXED_INVOICE_WITHOUT_ELEVATION')},
            {code: 'INM', label:  $translate('INVOICES.INDEXED_INVOICE_MIXED')},
            {code: 'Q11', label: $translate('INVOICES.Q101')},
            {code: 'EST', label: $translate('INVOICES.ESTIMATED_INVOICE')},
            {code: 'FRT', label: $translate('INVOICES.FLAT_RATE')}
        ];

        $scope.payment_methods = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'DBT', label: $translate('INVOICES.DEBIT')},
            {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')},
            {code: 'GBT', label: $translate('INVOICES.DEBIT_GBT')}
        ];

        $scope.invoices_payment_types = [
            {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
            {code: 'RCP', label: $translate('INVOICES.REMITTANCE')},
            {code: 'RFD', label: $translate('INVOICES.REFUND_PAYMENT')},
            {code: 'DFR', label: $translate('INVOICES.DEFERMENT')},
            {code: 'MAN', label: $translate('INVOICES.MANUAL')},
            {code: 'TPV', label: 'TPV'},
            {code: 'CMP', label: $translate('INVOICES.COMPENSATION')},
            {code: 'GES', label: 'GESICO'},
            {code: 'FAI', label: 'FALLIDO'}
        ];

        $scope.face_modes = [
            //{code: '', label: $translate('INVOICES.NO')},
            {code: 'FC', label: $translate('INVOICES.YES')}
        ];

        $scope.face_sent_modes = [
            {code: 'NO', label: $translate('INVOICES.NO')},
            {code: 'YES', label: $translate('INVOICES.YES')}
        ];

        $scope.face_sent_status_modes = [
            {code: 'Pagada', label: $translate('INVOICES.PAID')},
            {code: 'Registrada', label: $translate('INVOICES.FACE_REGISTER')},
            {code: 'Registrada en RCF', label: $translate('INVOICES.FACE_REGISTER_RCF')},
            {code: 'Rechazada', label: $translate('INVOICES.FACE_REJECT')},
            {code: 'Contabilizada la obligacin reconocida', label: $translate('INVOICES.FACE_COUNTED')}
        ];

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];

        $scope.invoices = [];
        $scope.ordering = {
            "id": $translate('INVOICES.CREATION_DATE'),
            "mixed_date": $translate('INVOICES.INVOICED_DATE'),
            "idn": $translate('INVOICES.NO_INVOICE'),
            "contract_id": $translate('INVOICES.CONTRACT'),
            "total": $translate('INVOICES.TOTAL_AMOUNT'),
            "customer_name": $translate('INVOICES.CUSTOMER_NAME'),
            "start_date": $translate('INVOICES.BILLING_PERIOD')
        };
        $scope.reconciled_status_mode = [
            {code: '0', label: $translate('INVOICES.NO')},
            {code: '1', label: $translate('INVOICES.YES')}
        ];

        $scope.is_foreign_account_values = [
            {code: '0', label: $translate('INVOICES.NO')},
            {code: '1', label: $translate('INVOICES.YES')}
        ];

        $scope.filters = {
            search: "",
            total_min: "",
            total_max: "",
            invoice_id: "",
            contract_id: "",
            status: "",
            agreement: null,
            state: null,
            not_remittable: '',
            vat_id: "",
            ever_been_returned: false,
            handmade: false,
            partial_search: false,
            invoicing_mode: "",
            platform: "",
            face_sent: "",
            face_last_status: "",
            hidden: "",
            reconciled: false,
            label: "",
            brand: ""
        };
        $scope.brands = [];

        $scope.date_filters = {
            product_date_range: null,
            date_range_from: null,
            date_range_to: null
        };

        $scope.filters_by_date = [
            {value: "creation", description: $translate('INVOICES.Creation date range')},
            {value: "period", description: $translate('INVOICES.Billing period date range')},
            {value: "remit", description: $translate('INVOICES.REMIT_DATE')},
            {value: "paid", description: $translate('INVOICES.PAID_DATE')},
            {value: "unpaid", description: $translate('INVOICES.UNPAID_DATE')},
            {value: "invoiced", description: $translate('INVOICES.INVOICED_DATE')}
        ];


        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watchCollection('date_filters', function (newVal, oldVal) {
            if (oldVal.product_date_range !== null) {
                var sel_option = oldVal.product_date_range.value;
                if (oldVal.date_range_from !== null) {
                    $scope.filters[sel_option + "_date_from"] = null;
                }
                if (oldVal.date_range_to !== null) {
                    $scope.filters[sel_option + "_date_to"] = null;
                }
            }
            if (newVal.product_date_range !== null) {
                var sel_option = newVal.product_date_range.value;
                if (newVal.date_range_from !== null) {
                    var from_date = newVal.date_range_from;
                    $scope.filters[sel_option + "_date_from"] = from_date;
                }
                if (newVal.date_range_to !== null) {
                    var to_date = newVal.date_range_to;
                    $scope.filters[sel_option + "_date_to"] = to_date;
                }
            }
        });

        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$watch('filters.company', function (newVal, oldVal) {
            if(newVal != null){
                let invoice_series_data = {
                    'company': newVal
                }
        
                InvoiceSeriesAPI.listInvoiceSeries(invoice_series_data).then(function (data) {
                    if(data.results != undefined){
                        $scope.invoices_series = data.results
                    }
                });
            }
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/
        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/
        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'PAI')
                styles += ' ' + 'success';
            if (result.status == 'PEN' || result.status == 'DFR')
                styles += ' ' + 'info';
            if (result.status == 'INV')
                styles += ' ' + 'invoiced';
            if (result.status == 'DRF' || result.status == 'SNT')
                styles += ' ' + 'warning';
            if (result.status == 'UNP')
                styles += ' ' + 'danger';

            return styles;
        };

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.downloadEInvoice = function () {
            if ($scope.counter == 1) {
                var invoice_id = filtersUtils.getItemsToDownloadToInt($scope.itemsToDownload)[0];
                var url = API_URL + '/invoices/download/facturae/' + invoice_id + '?token=' + $cookies.get("token");
                $window.open(url);
                return false;
            } else if ($scope.counter > 1) {
                var task = new Task();
                task.type = 'EIDW';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk download generation Task success'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.Bulk download generation Task error'), true);
                });

            }
        };

        $scope.downloadEInvoiceWithoutPDF = function () {
            var task = new Task();
            task.type = 'EIDW';
            task.params = self.getJSONParams($scope.itemsToDownload);
            task.params.without_pdf = false;
            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('INVOICES.Bulk download generation Task success'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('INVOICES.Bulk download generation Task error'), true);
            });
        };
        $scope.update_print_invoices = function () {
            if ($scope.update.print_invoices == undefined) {
                $scope.update.print_invoices = false;
            }
            $scope.update.update_type = 'print_invoices';
            $scope.bulk_update();
        };
        $scope.downloadPDF = function (template) {
            if ($scope.counter == 1) {
                var params = filtersUtils.getItemsToDownloadParams('ids', $scope.itemsToDownload);
                var url = API_URL + '/invoices/download/pdf/' + params + '&token=' + $cookies.get("token");
                $window.open(url);
                return false;
            } else if ($scope.counter > 1) {
                var task = new Task();
                task.type = 'IVDW';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.params.template = template;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk download PDF'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                });

            }
        };

        $scope.downloadCSV = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'ICSV';
                task.params = self.getJSONParams($scope.itemsToDownload);
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk download CSV'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                });
            }
        };

        $scope.downloadRemittableReport = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                var request_params = {'slug': 'remittable_report',
                                      'values': self.getJSONParams($scope.itemsToDownload)};

                task.type = 'GREP';
                task.params = request_params;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk download CSV'), true);
                    $scope.backgroundTasks.push(data.data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                });
            }
        };

        $scope.update_remittable_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'IRMU';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk remittable CSV'), true);
                    $scope.backgroundTasks.push(data.data.id);
                    $scope.update_list.reason = '';
                    $scope.update_list.set_remittable = false
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    $scope.update_list.reason = '';
                    $scope.update_list.set_remittable = false
                });

            }
        };
        $scope.add_comment_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'IACM';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk_Comment'), true);
                    $scope.backgroundTasks.push(data.data.id);
                    $scope.update_list.comment = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    $scope.update_list.comment = '';
                });

            }
        };
        $scope.massive_compensated_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'INVC';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.MASSIVE_COMPENSATION'), true);
                    $scope.backgroundTasks.push(data.id);
                    $scope.update_list.reason = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.MASSIVE_COMPENSATION_ERROR'), true);
                    $scope.update_list.reason = '';
                });

            }
        };
         $scope.massive_print_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'IPIN';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.MASSIVE_PRINT_INVOICES'), true);
                    $scope.backgroundTasks.push(data.id);
                    $scope.update_list.comment_print_invoices = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.MASSIVE_PRINT_INVOICES_ERROR'), true);
                    $scope.update_list.comment_print_invoices = '';
                });

            }
        };
        $scope.set_reconciled_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'SIAC';
                $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk_Comment'), true);
                    $scope.backgroundTasks.push(data.data.id);
                    $scope.update_list.comment = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    $scope.update_list.comment = '';
                });

            }
        };
        $scope.set_hidden_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'SIAH';
                $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk_Comment'), true);
                    $scope.backgroundTasks.push(data.id);
                    $scope.update_list.comment = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    $scope.update_list.comment = '';
                });
            }
        };
        $scope.massive_labels_invoices = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'LMAT';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                $scope.update_list.scope = 'invoices'
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('LABELS.MASSIVE_LABELS'), true);
                    $scope.backgroundTasks.push(data.id);
                    $scope.update_list.mode = ''
                    $scope.update_list.label = '';
                    $scope.update_list.comment_massive_label = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('LABELS.MASSIVE_LABELS_ERROR'), true);
                    $scope.update_list.mode = '';
                    $scope.update_list.label = '';
                    $scope.update_list.comment_massive_label = '';
                });
            }
        };
        $scope.massive_refund_recipt_change_invoice_bank_account = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'RCIB';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                $scope.update_list.scope = 'invoices'
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.REFUND_RECEIPT_BANK_ACCOUNT'), true);
                    $scope.backgroundTasks.push(data.id);
                    $scope.update_list.comment_massive_refund_receipt = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.REFUND_RECEIPT_BANK_ACCOUNT_ERROR'), true);
                    $scope.update_list.comment_massive_refund_receipt = '';
                });
            }
        };
        $scope.generate_overview_invoice = function () {
            $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;

            var task = new Task();
            task.type = 'INOG';
            task.params = $scope.update;

            task.start().data(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('INVOICES.Generating_overview_invoice'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('INVOICES.Generating_overview_invoice_error'), true);
            });
        };
        $scope.generate_overview_unpaid_invoice = function () {
            $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;

            var task = new Task();
            task.type = 'IUPG';
            task.params = $scope.update;

            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('INVOICES.Generating_overview_unpaid_invoices'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('INVOICES.Generating_overview_unpaid_invoices_error'), true);
            });
        };
        $scope.download_custom_pdfs = function () {
            $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;

            var task = new Task();
            task.type = 'CPDF';
            task.params = $scope.update;

            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('INVOICES.Custom_invoice_pdf'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('INVOICES.Custom_invoice_pdf_error'), true);
            });
        };

        $scope.set_contract_data = function () {
            $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;

            var task = new Task();
            task.type = 'ISCD';
            task.params = {'ids': $scope.update.ids};

            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('INVOICES.Setting_contract_data'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('INVOICES.Setting_contract_data_error'), true);
            });
        };

        $scope.send_to_sii = function () {
            if ($scope.counter != 0) {
                var task = new Task();
                task.type = 'CSII';
                $scope.update_list.ids = self.getJSONParams($scope.itemsToDownload).ids;
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('INVOICES.Bulk_Send_to_sii'), true);
                    $scope.backgroundTasks.push(data.data.id);
                    $scope.update_list.comment = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('INVOICES.Bulk_Send_to_sii_error'), true);
                    $scope.update_list.comment = '';
                });

            }
        };

        $scope.canSelectInvoices = function () {
            return this.canDownloadEInvoice() || this.canDownloadCSV() || this.canDownloadPDFInovice();
        };

        $scope.canDownloadEInvoice = function () {
            return UserInfo.hasPerm('invoices.download_einvoices');
        };

        $scope.canDownloadCSV = function () {
            return UserInfo.hasPerm('invoices.download_invoices_csv');
        };

        $scope.canDownloadPDFInovice = function () {
            return UserInfo.hasPerm('invoices.download_invoices_pdf');
        };

        $scope.canCreateInvoice = function () {
            return UserInfo.hasPerm('invoices.add_invoice');
        };

        $scope.canSelectInvoicesForActions = function () {
            return this.can_change_remit_date() || this.can_delete_invoice() || this.can_sent_invoice() || this.can_sent_draft() || this.can_definitive_invoice();
        };

        $scope.can_change_remit_date = function () {
            return UserInfo.hasPerm('invoices.change_invoice');
        };
        $scope.can_change_invoice_remitable = function () {
            return UserInfo.hasPerm('invoices.change_invoice');
        };
        $scope.can_delete_invoice = function () {
            return UserInfo.hasPerm('invoices.delete_draft');
        };

        $scope.can_sent_invoice = function () {
            return UserInfo.hasPerm('invoices.set_invoice_sent');
        };

        $scope.can_sent_draft = function () {
            return UserInfo.hasPerm('invoices.set_invoice_draft');
        };

        $scope.can_definitive_invoice = function () {
            return UserInfo.hasPerm('invoices.set_invoice_definitive');
        };

        $scope.can_pay_invoice = function () {
            return UserInfo.hasPerm('invoices.add_payment_invoice');
        };

        $scope.can_unpaid_invoice = function () {
            return UserInfo.hasPerm('invoices.add_unpaid_invoice');
        };

        $scope.can_send_einvoice = function () {
            return UserInfo.hasPerm('invoices.send_einvoice');
        };
        $scope.canCreateUnpaidInvoice = function () {
            return UserInfo.hasPerm('invoices.add_unpaid_invoice');
        };

        $scope.can_add_invoice_comments = function () {
            return UserInfo.hasPerm('invoices.retrieve_invoices')
        };
        $scope.canShowReconciledInvoicesStatus = function () {
            return UserInfo.hasPerm('invoices.show_reconciled');
        };
        $scope.canShowHiddenInvoices = function () {
            return UserInfo.hasPerm('invoices.set_invoice_as_hidden');
        };
         $scope.canShowCompensatedInvoicesTask = function () {
            return UserInfo.hasPerm('invoices.show_compensate_invoice_task');
        };
        $scope.canAssignLabel = function () {
                return UserInfo.hasPerm('labels.add_labelledobject');
            };
        $scope.canRefundAndChangeBankAccountInvoiceReceipt = function () {
                return UserInfo.hasPerm('invoices.can_refund_and_change_bank_invoice_receipt');
        };
        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        $scope.canDuplicateInvoice = function () {
            return UserInfo.hasPerm('invoices.add_invoice');
        };

        $scope.canRefundNewPrices = function () {
            return UserInfo.hasPerm('invoices.add_invoice');
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        AgreementsMaster.loadAgreements().then(function (response) {
            $scope.agreements = response;
        });

        $scope.onChangeStartDate = function (date) {
            $scope.update.start_date = date.formated ? date.formated.replace(new RegExp('([0-9]+)\/([0-9]+)\/([0-9]+)'), '$3-$2-$1') : null
        };

        $scope.onChangeFinishDate = function (date) {
            $scope.update.finish_date = date.formated ? date.formated.replace(new RegExp('([0-9]+)\/([0-9]+)\/([0-9]+)'), '$3-$2-$1') : null
        };
        $scope.onChangeStartDateCom = function (date) {
            $scope.update.start_date_com = date.formated ? date.formated.replace(new RegExp('([0-9]+)\/([0-9]+)\/([0-9]+)'), '$3-$2-$1') : null
        };

        $scope.onChangeFinishDateCom = function (date) {
            $scope.update.finish_date_com = date.formated ? date.formated.replace(new RegExp('([0-9]+)\/([0-9]+)\/([0-9]+)'), '$3-$2-$1') : null
        };

        let invoice_series_data = {
            'all': 'True'
        }

        InvoiceSeriesAPI.listInvoiceSeries(invoice_series_data).then(function (data) {
            $scope.invoices_series = data.results
        });

        BrandsList.all().then(function (response) {
            if(response)
                $scope.brands = [{
                    'code': 'null',
                    'name': $translate('BRANDS.NONE')
                }].concat(response)
        });
    }
]

export { InvoicesCtrl }
