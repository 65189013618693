{
  "SUPPLIES": {
    "TITLE": "Supplies",
    "STATE": "State",
    "ANY": "Any",
    "STATUS": "Status",
    "RATE": "Rate",
    "CUPS": "CUPS",
    "SUPPLY_POINT": "Supply point",
    "REPORTS":"Reports",
    "CONSUMPTION_REPORT_AVERAGE":"Consumption averages report",
    "CONSUMPTION_REPORT":"Consumption report",
    "COMPARATIVE_REPORT": "Comparative report",
    "COMPARATIVE_REPORT_AVERAGE": "Comparative report averages",
    "COMMENTS": "Comments",
    "FILES": "Files",
    "DATA_SUPPLY": "Data supply",
    "APC": "APC",
    "CONTRACTED_POWER": "Contracted power",
    "OWNER_ADDRESS_SUPPLY": "Owner and address supply",
    "ADDRESS": "Address",
    "TOWN": "Town",
    "ZIP_CODE": "Zip code",
    "HOLDER_SUPPLY": "Holder supply",
    "FROM": "From",
    "TO": "To",
    "GENERATE_REPORT": "Generate report",
    "ANY_RATE": "Any rate",
    "EXPORT_CSV": "Export to CSV (Excel)",
    "DEVIATION_HIGHLIGHT": "Deviation highlight",
    "NO_RESULTS": "No results for indicated data",
    "TRY_OTHER_DATE": "Try different criterias for report",
    "ERROR": "An error has occurred",
    "CUPS_FIRST_DATE": "Cups associated with the first date",
    "CUPS_SECOND_DATE": "Cups associated with the second date",
    "SHOW_CUPS": "See list of CUPS associated with the report",
    "HIDE_CUPS": "Hide list of CUPS associated with the report",
    "HISTORY_IMPORTS": "History imports",
    "NEW_IMPORT": "New import",
    "SECONDARY_HUB": "Consumptions report",
    "PENDING": "Pending",
    "UNDOING": "Undoing",
    "DOING": "Doing",
    "UNDO": "Undo",
    "CANCELED": "Canceled",
    "FINISHED": "Finished",
    "DISCARDED": "Discarded",
    "IMPORT_DATA": "Import data from secondary hub",
    "CREATED": "created",
    "EXISTENT": "existent",
    "INCIDENCE": "incidence",
    "INCIDENCES": "incidences",
    "IMPORT_CONSUMPTIONS": "Import consumptions",
    "TOLL_RATE": "Toll rate",
    "ALL": "All",
    "ACTIVE": "Active",
    "INACTIVE": "Inactive",
    "IMPORT_BACKGROUND": "Import is running in background",
    "ERROR_SENDING_TASK": "An error has occurred sending the task",
    "IMPORT_FINISHED": "Import finished",
    "LOADING": "Loading...",
    "Load SIPS": "Load SIPS",
    "Load": "Load",
    "CONTRACTS_FOR_SUPPLY": "Contracts",
    "EXPORT_CSV_DAY": "Export daily report to CSV",
    "READINGS": "Readings",
    "START_DATE": "Start date",
    "FINISH_DATE": "End date",
    "REACTIVE_ENERGY": "Reactive energy",
    "ACTIVE_ENERGY": "Active energy",
    "DEMAND_POWER": "Demand power",
    "POSITIVE_NUMBER_ERROR": "Must be a positive number",
    "NUMBER_ERROR": "Must be a number",
    "DATE_ERROR": "Date must be included",
    "CONSUMPTION_READINNG_CREATED": "Consumption Reading created successfully",
    "CONSUMPTION_READINNG_ERROR": "There are errors in Consumption Reading",
    "ANY_SOURCE": "Any source",
    "ACTIVE_CONTRACT": "This supply has an active contract assigned ",
    "LIST_CONSUMPTION": "Consumptions list",
    "SEARCH_BY_SOURCE": "Search by source",
    "DEFAULT_SOURCE": "Any",
    "DATE": "Date",
    "TIME": "Time",
    "VALUE": "Value",
    "PERIOD": "Period",
    "SOURCE": "Source",
    "WINTER": "Winter",
    "SUMMER": "Summer",
    "ADD_READING": "Add reading",
    "CLOSE": "Close",
    "ELECTRICITY_METER": "Electricity meter",
    "DATA_ELECTRICITY_METER": "Data electricity meter",
    "SELECT_ELECTRICITY_METER": "Select an electricity meter",
    "NO_LAST_READING": "No last reading found.",
    "LAST_READING_MANUAL": "Last reading is manual.",
    "TO_DATE_GREATER_FROM_DATE": "'From date' must be lower than 'To date'.",
    "Double consumption report generation Task success": "Double consumption report generation Task success",
    "Double consumption report generation Task error": "Double consumption report generation Task error",
    "Daily consumption report generation Task success": "Daily consumption report generation Task success",
    "Daily consumption report generation Task error": "Daily consumption report generation Task error",
    "Single consumption report generation Task success": "Single consumption report generation Task success",
    "Single consumption report generation Task error": "Single consumption report generation Task error",
    "VIEW": "View",
    "TYPE": "Type",
    "TYPE_UPDATED": "Supply type updated successfully.",
    "TYPE_UPDATED_ERROR": "Error updating supply type.",
    "MAGNITUDE": "Magnitude",
    "TERM": "Term",
    "ORIGIN_READING": "Origin reading",
    "FINAL_READING": "Final reading",
    "READING_UPDATED_OK": "Reading successfully updated",
    "CITY": "City",
    "SUPPLY_INFO_RATE_POWER": "Rate / Powr Information",
    "MAX_AUTH_POWER": "Max authorised power bie",
    "VOLTAGE": "Voltage",
    "ACCESS_RIGHTS": "Access rights",
    "EXTENSION_RIGHTS": "Access rights",
    "CONSUMPTION_PROFILE_TYPE": "Consumption profile type",
    "CONTRACT_INFO": "Contract Information",
    "VAT_ID" : "NIF/CIF",
    "CONTRACT_START_DATE": "Contract start date",
    "LAST_TRADING_DATE": "Last trading date",
    "LAST_RECRUITMENT_DATE": "Last recruitment date",
    "LAST_READING_DATE": "Last reading date",
    "OTHERS": "Others",
    "USUAL_HOUSING": "Usual housing",
    "NT_CODE": "Nt code",
    "ICP_INSTALLED": "ICP instalaled",
    "DEPOSIT_WARRANTY": "Deposit warranty",
    "OWNS_MEASUREMENT_DEVICE": "Owns measurement device",
    "OWNS_ICP": "Owns ICP",
    "PS_CLASSIFICATION": "PS classification",
    "NON_PAYMENT": "Non payment",
    "CONSUMPTIONS": "Consumptions",
    "CUPS_NPT_FOUND": "Cups not found",
    "FORCE_READING": "Force reading",
    "START_DATE_READING": "Initial date reading",
    "FINISH_DATE_READING": "Finish date reading",
    "ELECTRICITY_METERS": "Electricity meters",
    "SERIAL_NUMBER": "Serial number",
    "BRAND": "Brand",
    "NUMBER_PERIODS": "Number of periods",
    "ADD_ELECTRICITY_METER": "Add electricity meter",
    "CREATE": "Create",
    "ELECTRICITY_METER_CREATED_OK": "Electricity meter successfully created",
    "NO_ELECTRICITY_METERS": "There isn't any electricity meter. Create one first if you want to do operations over readings.",
    "SUPPLY_FULLNAME": "Name",
    "SUPPLY_SURNAME_1": "Surname 1",
    "SUPPLY_SURNAME_2": "Surname 2",
    "CALENDARS_FOR_SUPPLY": "Calendars",
    "NEW_CALENDAR_SUPPLY": "Add calendar for supply",
    "ADD_CALENDAR_FOR_SUPPLY": "Add",
    "CANCEL": "Cancel",
    "CALENDAR_FOR_SUPPLY_ADDED": "Calendar added to supply successfully.",
    "DELETE_CALENDAR_FOR_SUPPLY": "Delete calendar for supply",
    "YES": "Yes",
    "NO": "No",
    "CONFIRM_DELETE_CALENDAR_FOR_SUPPLY": "Do you really want to remove the calendar for the supply?",
    "CALENDAR_FOR_SUPPLY_DELETED": "Calendar for supply removed.",
    "COMMERCIAL": "Commercial",
    "DISTRIBUTOR": "Distributor",
    "UPDATE_SUPPLY": "Update supply",
    "CONFIRM_UPDATE_SUPPLY": "Now you must introduce a reason to update the supply.",
    "NOT_IN_SIPS": "This supply doesn't exists in SIPS database.",
    "DRAFT_CONTRACT": "This supply has an active draft assigned ",
    "INACTIVE_CONTRACT": "This supply has an inactive contract assigned ",
    "commercial_id" : "Commercial Code",
    "remote_management_active" : "Remote Management Active",
    "electricity_meter_stage" : "Electricity Meter Stage",
    "self_consumption" : "Self consumption code",
    "contract_type" : "Contract Type",
    "invoicing_period" : "Invoicing Period",
    "bie" : "BIE",
    "bie_start_date" : "BIE start date",
    "bie_finish_date" : "BIE finish date",
    "apm" : "APM",
    "apm_start_date" : "APM start date",
    "apm_finish_date" : "APM finish date",
    "intensity_transformation_relationship" : "Intensity transformation relationship",
    "cnae" : "CNAE",
    "power_control_mode" : "Power control mode",
    "cgp_power" : "CGP Power",
    "electricity_meter_dh" : "Electricity Meter DH",
    "electricity_meter_accessibility" : "Electricity Meter Acessibility",
    "supply_availability" : "Supply Availability",
    "supply_availability_reason" : "Supply Availability Reason",
    "measured_voltage" : "Measured Voltage",
    "inquiry" : "Inquiry",
    "inquiry_reason" : "Inquiry Reason",
    "supply_type" : "Supply Type",
    "PROGRAMMING_UNITS": "Programming Units",
    "ACTIONS": "Actions",
    "LINK_PROGRAMMING_UNITS": "Link to Programming Unit",
    "UNLINK_PROGRAMMING_UNITS": "Unlink to Programming Unit",
    "READING_ORIGIN": "Origin",
    "DOWNLOAD_REPORT": "Download Report",
    "ACTIVE_CONSUMPTIONS": "Active energy consumptions",
    "REACTIVE_CONSUMPTIONS": "Reactive energy consumptions",
    "SINGLE-PHASE": "Single-phase",
    "TRIPHASE": "Triphase",
    "GENERATE_ISSUE": "Generate Event",
    "INVALID_CITY_VALUE": "invalid city value",
    "TOWN_MANDATORY": "City is required",
    "NAME_REQUIRED": "Name is required",
    "ZIP_REQUIRED": "Zip is required",
    "BT_MEASURE_LOSS": "BT measure loss",
    "CONNECTION_NUMBER": "Connection number",
    "ENERGY_ORIGIN": "Energy origin",
    "AUTO_CONSUMPTIONS": "Autoconsumptions Types",
    "AUTO_CONSUMTION": "Autoconsumption",
    "CIE": "CIE",
    "CIE_CODE": "CIE Code",
    "CIE_VOLTAGE": "Voltage",
    "CIE_MAX_POWER": "Max power",
    "CIE_INSTALLER_CODE": "Installer Code",
    "CIE_INSTALLER_NAME": "Installer Name",
    "CIE_ISSUED_ON": "Issue date",
    "CIE_ACTIVATED_ON": "Activation date",
    "CIE_FINISHED_ON": "Finish date",
    "REGISTRY": "Registry",
    "ATR_REQUEST_LIST": "ATR Request List for cups: ",
    "YEARLY_CONSUMPTION": "Yearly Consumption",
    "YEARLY_CONSUMPTION_FROM": "Annual average between ",
    "YEARLY_CONSUMPTION_TO": " and ",
    "CONFIRM_BULK_UPDATE": "The change will be performed in the selected supplies!"
  },
	"RECORDS": {
		"BUSINESS_RECORD_ADDED": "Business record added",
		"ERROR_ADDING_RECORD": "Error adding record",
		"CONTACTS": "Contacts",
		"NEW_BUSINESS_RECORD": "New business record",
		"NAME": "Name",
		"CODE": "Code",
		"SAVE": "Save",
		"CANCEL": "Cancel",
		"SEE": "See",
		"DATA": "Data",
		"BUSINESS_RECORD_DATA": "Business record data",
		"COMMENTS": "Comments",
		"FILES": "Files",
		"UPDATE": "Update",
		"NAME_MANDATORY": "Field 'name' is mandatory",
		"CODE_MANDATORY": "Field 'code' is mandatory",
		"BUSINESS_RECORD_UPDATED": "Business record updated",
		"ERROR_UPDATING_RECORD": "Error updating record",
		"DELETE": "Delete",
		"YES": "Sí",
		"NO": "No",
		"COMMERCIAL_OFFERS": "Commercial Offers",
		"COMMERCIAL_OFFER": "Commercial Offer",
		"SEARCH": "Search...",
		"ALL": "ALL",
		"EDIT": "Edit",
		"DELETE_COMMERCIAL_OFFER": "Delete price rate offer?",
		"COMMERCIAL_OFFER_CREATED_SUCCESSFULLY": "Price rate create successfully.",
		"COMMERCIAL_OFFER_CREATED_ERROR": "Price rate not created. ",
		"COMMERCIAL_OFFER_CODE_ERROR": "Code included in price rate already exist.",
		"ERROR_OCCURRED": "Error occurred",
		"COMMERCIAL_OFFER_DATA": "Commercial price rate data",
		"SUCCESS_COMMERCIAL_OFFER_UPDATED": "Commercial price rate update successfully.",
		"ERROR_COMMERCIAL_OFFER_UPDATED": "Error. Commercial price rate not be updated.",
		"PRICES": "Price Rates",
		"NEW_PRICE": "New Price rate",
		"SELECT_RATE": "Select rate: ",
		"ANY_RATE": "Select a rate",
		"POWER": "Power",
		"ACTIVE_ENERGY": "Active energy",
		"NOT_PRICE_OFFER": "There is not prices for that offer.",
		"OFFER_PRICE_DELETED": "Rate type price for commercial offer deleted successfully.",
		"OFFER_PRICE_UPDATED": "Rate type price for commercial offer updated successfully.",
		"BUSINESS_RECORD_DELETED": "Business record deleted",
		"ERROR_DELETING_RECORD": "Error deleting record",
		"TABLE_P1": "P1",
		"TABLE_P2": "P2",
		"TABLE_P3": "P3",
		"TABLE_P4": "P4",
		"TABLE_P5": "P5",
		"TABLE_P6": "P6",
		"SELECT_COMMERCIAL_OFFER": "Select commercial offer",
		"CONSUMPTION": "Consumption",
		"ENERGY_TOLL_ADJUST": "Energy toll adjust",
		"OTHER_ENERGY_CONCEPTS": "Other energy concepts",
		"DISCOUNT": "Discount",
		"REACTIVE_ENERGY_CONSUMPTION": "Reactive energy consumption",
		"REACTIVE_ENERGY_COST": "Reactive energy cost",
		"INVOICED_POWER": "Invoiced power",
		"POWER_TERM_PRICE": "Power term price",
		"ENERGY_PRICE": "Energy term price",
		"REACTIVE_PRICE": "Reactive price",
		"POWER_TERM_REGULARIZATION": "Power term regularization",
		"OVERPOWERING": "Overpowering",
		"SOCIAL_BOND": "Social bond",
		"power_surcharge_for_icp_lack": "Power surcharge for ICP lack",
		"other_power_concepts": "Other power concepts",
		"CUPS": "CUPS",
		"supply_address": "Supply address",
		"rate_type": "Rate type",
		"marketer": "Marketer",
		"CLIENT": "Client",
		"CONTRACTED": "Contracted",
		"equipment_rental": "Equipment rental",
		"iberdrola_pymes": "Iberdrola PYMES assistance",
		"electrical_emergency_service": "Electrical emergency service",
		"managemenet_support": "Management support",
		"supply_quality_bond": "Supply quality bond",
		"penalty": "Penalty",
		"electricity_maintenance": "Electricity maintenance",
		"professional_assistance_service": "Professional assistance service",
		"eon_assistance": "E.On assistance service",
		"other_complements": "Other complements",
		"INVOICE_STUDY": "Comparative",
		"invoiceFromDate": "Invoicing initial date",
		"invoiceToDate": "Invoicing end date",
		"WATIUM": "Watium",
		"DIFFERENCE": "Difference",
		"electricity_tax_coefficient": "Electricity tax coefficient",
		"electricity_tax": "Electricity tax",
		"energy_term_cost": "Energy term cost",
		"energy_toll_adjust": "Energy toll adjust",
		"other_energy_concepts": "Other energy concepts",
		"TOTAL_ENERGY_TERM": "Total energy term",
		"SUBTOTAL": "Subtotal: power + consumption + reactive + tax",
		"taxable_iva": "Total - Taxable IVA",
		"POWER_TERM_COST": "Power term cost",
		"total_power_costs": "Total power term",
		"ENERGY": "Energy",
		"REACTIVE_ENERGY": "Reactive energy",
		"TOTAL_REACTIVE_ENERGY": "Total reactive energy",
		"ELECTRIC_TAXABLE": "Electric taxable",
		"total_taxable_iva": "Total taxable IVA 21%",
		"last_invoice_total": "LAST INVOICE TOTAL",
		"no_consumption_data": "No consumptions data for this supply",
		"no_prices_data": "No prices data for this rate",
		"initial_read_date": "Initial read date",
		"final_read_date": "Final read date",
		"number_of_days": "Number of days",
		"maximeter_power_demand": "Maximeter power demand",
		"contracted_power": "Contracted power",
		"recommended_power": "Recommended power",
		"invoiced_power_in_watium": "Invoiced power in Watium",
		"actual": "Actual",
		"saving": "SAVING",
		"EXP_COMMERCIAL_OFFER": "Com. Offer",
		"NIF/CIF": "NIF/CIF",
		"DATE": "Date",
		"SENT": "Sent",
		"PENDING": "Pending",
		"CANCELED": "Canceled",
		"REJECTED": "Rejected",
		"ACCEPTED": "Accepted",
		"COMMERCIAL_PRICE_RATE": "Commercial Price Rate",
		"NEW_COMMERCIAL_PRICE_RATE": "New Commercial Price Rate",
		"business_record": "Business record",
		"UPDATE_SUCCESS": "Successful update",
		"UPDATE_ERROR": "Error on updating",
		"new_rate_type": "New rate type",
		"CONFIRM_DELETE": "Do you want to delete this bussiness record?",
		"ACTIONS": "Actions",
		"CHANGE_STATUS": "Change status",
		"NEW_STATUS": "New status",
		"CHANGE_STATUS_OK": "Commercial offers successfully updated. ",
		"DOWNLOAD_RESUME": "Download resume",
		"DOWNLOAD_DETAIL": "Download detail",
		"Download PDF Task created": "Download PDF Task created",
		"Download generation Task error": "Download generation Task error",
		"ADD_CONCEPT": "Add concept",
		"SAVING": "Saving",
		"ANNUAL_SAVING": "Annual saving",
		"RESULT": "Result",
		"POSITIVE": "Positive",
		"NEGATIVE": "Negative",
		"CONTACT_DATA": "Contact data",
		"NEW_CONTACT": "New contact",
		"CREATE": "Create",
		"CREATE_CONTACT": "Create contact",
		"CONTACT_CREATED_SUCESSFULLY": "Contact created successfully",
		"CONTACT_UPDATED_SUCESSFULLY": "Contact updated successfully",
		"CONTACT_DELETED_SUCESSFULLY": "Contact deleted successfully",
		"INFORMATION": "Information",
		"WRITE_INFORMATION": "Write information about the contact",
		"INVOICE_CONSUMPTION": "Invoice consumption",
		"REPORT_DATA": "Report data",
		"SUPPLY_DATA": "Supply data",
		"INVOICE_PERIOD": "Invoice period",
		"COMPARATIVE_DATA": "Comparative Header",
		"COMPARATIVE_REPORT": "Comparative report",
		"COMPARATIVE_REPORTS": "Comparative reports",
		"NEW_COMPARATIVE_REPORT": "New Comparative Report",
		"INVOICE_PRICES": "Invoice prices",
		"OFFER_PRICES": "Offer prices",
		"OFFER": "Offer",
		"INVOICE": "Invoice",
		"ITEM": "Item",
		"ACTION": "Action",
		"DAYS": "Days",
		"TAX_TOTAL": "Tax total",
		"RECORD_CREATED_SUCESSFULLY": "Record created sucessfully",
		"RECORD_DELETED_SUCESSFULLY": "Record deleted sucessfully",
		"RECORD_UPDATED_SUCESSFULLY": "Record updated sucessfully",
		"POWER_STUDY": "Power study",
		"CREATE_COMPARATIVE_REPORT": "Create comparative report",
		"NO_POWER_STUDY": "No power study",
		"COMMERCIAL_COMMENTS": "Activity schedule Comments"
    }
}
