// Controllers
import { CreateProviderInvoiceCtrl } from './controllers/CreateProviderInvoiceCtrl'
import { ProviderCreateCtrl } from './controllers/ProviderCreateCtrl'
import { ProviderDetailCtrl } from './controllers/ProviderDetailCtrl'
import { ProviderInvoiceDetailCtrl } from './controllers/ProviderInvoiceDetailCtrl'
import { ProviderListCtrl } from './controllers/ProviderListCtrl'
import { ProvidersCtrl } from './controllers/ProvidersCtrl'
import { ProvidersInvoicesCtrl } from './controllers/ProvidersInvoicesCtrl'
import { ProviderInvoicesSyncCtrl } from './controllers/ProviderInvoicesSyncCtrl'

// Directives
import { providerInvoiceChangeProvider } from './directives/providerInvoiceChangeProvider'
import { providerInvoiceChangeStatus } from './directives/providerInvoiceChangeStatus'
import { providerInvoiceDeletion } from './directives/providerInvoiceDeletion'
import { providerInvoiceSetExpirationDate } from './directives/providerInvoiceSetExpirationDate'
import { providerInvoiceSetPaidDate } from './directives/providerInvoiceSetPaidDate'
import { providerInvoiceSetReceivePaperInvoice } from './directives/providerInvoiceSetReceivePaperInvoice'
import { reconciledToString } from './directives/reconciledToString'

// Services
import { ProviderFactory } from './services/Provider'
import { ProviderAPI } from './services/ProviderAPI'
import { ProviderInvoiceFactory } from './services/ProviderInvoice'
import { ProviderInvoiceAPI } from './services/ProviderInvoiceAPI'

const providers = angular.module('providers', ['pascalprecht.translate', ]);

providers.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

providers.config(['$resourceProvider',
    function($resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
    }
])

providers
    .controller('CreateProviderInvoiceCtrl', CreateProviderInvoiceCtrl)
    .controller('ProviderCreateCtrl', ProviderCreateCtrl)
    .controller('ProviderDetailCtrl', ProviderDetailCtrl)
    .controller('ProviderInvoiceDetailCtrl', ProviderInvoiceDetailCtrl)
    .controller('ProviderListCtrl', ProviderListCtrl)
    .controller('ProvidersCtrl', ProvidersCtrl)
    .controller('ProvidersInvoicesCtrl', ProvidersInvoicesCtrl)
    .controller('ProviderInvoicesSyncCtrl', ProviderInvoicesSyncCtrl)
    .directive('providerInvoiceChangeProvider', providerInvoiceChangeProvider)
    .directive('providerInvoiceChangeStatus', providerInvoiceChangeStatus)
    .directive('providerInvoiceDeletion', providerInvoiceDeletion)
    .directive('providerInvoiceSetExpirationDate', providerInvoiceSetExpirationDate)
    .directive('providerInvoiceSetPaidDate', providerInvoiceSetPaidDate)
    .directive('providerInvoiceSetReceivePaperInvoice', providerInvoiceSetReceivePaperInvoice)
    .directive('reconciledToString', reconciledToString)
    .factory('Provider', ProviderFactory)
    .factory('ProviderInvoice', ProviderInvoiceFactory)
    .service('ProviderAPI', ProviderAPI)
    .service('ProviderInvoiceAPI', ProviderInvoiceAPI)

export { providers }
