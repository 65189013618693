{
  "TASKS": {
    "TITLE": "Tareas en segundo plano",
    "ERROR": "Error",
    "TODO": "Por hacer",
    "TO_CANCEL": "Por cancelar",
    "TO_UNDO": "Por deshacer",
    "UNDOING": "Deshaciendo",
    "DOING": "Ejecutando",
    "CANCELED": "Cancelada",
    "FINISHED": "Finalizada",
    "DISCARDED": "Deshecha",
    "DOWNLOAD": "Descargar",
    "UNDO": "Deshacer",
    "CANCEL": "Cancelar",
    "REPORT": "Informe",
    "REVERT": "Revertir",
    "PAUSED":"Pausada"
  }
}