{
  "AGREEMENTS": {
    "TITLE": "Agreements",
    "SEARCH": "Search...",
    "STATE": "State",
    "ANY": "Any",
    "ALL": "All",
    "CODE": "Code",
    "NIF/CIF": "NIF/CIF",
    "AGREEMENT_DATA": "Agreement's data",
    "USER_MANAGEMENT": "Web users",
    "FEES_RANGE": "Ranges fees",
    "COMMENTS": "Comments",
    "FILES": "Files",
    "NAME": "Name",
    "DNI/CIF": "DNI/CIF",
    "EMAIL": "E-mail",
    "PHONE": "Phone",
    "MOBILE": "Mobile",
    "FAX": "Fax",
    "EXTERNAL_SYNCHRONIZATION_CODE": "External synchronization code",
    "GENERATES_COMMISSIONS": "Generates commissions",
    "YES": "Yes",
    "NO": "No",
    "AGREEMENTS_CONDITIONS": "Agreement's conditions",
    "MINIMUM_ACTIVE_CONTRACTS": "Minimum active contracts",
    "MAXIMUM_DELAY_PAYMENT": "Maximum delay payment (days)",
    "ADDRESS_DATA": "Address data",
    "ADDRESS": "Address",
    "TOWN": "Town",
    "ZIP_CODE": "Zip code",
    "BILLING": "Billing",
    "BANK_ACCOUNT": "Bank account",
    "IVA": "IVA",
    "IRPF": "IRPF",
    "LOADING": "Loading...",
    "RATE": "Rate",
    "CALCULATION_BASED_ON": "Calculation based on",
    "FEE_ANNUAL": "Fee (annual)",
    "POWER_TERM": "Power term",
    "ENERGY_TERM": "Energy term",
    "SAVE": "Save",
    "DELETE": "Delete",
    "ANNUAL_FEE": "Annual fee",
    "EURO/YEAR": "€/year",
    "POSITIVE_NUMBER_ERROR": "You must enter a positive number",
    "MINIMUM_ERROR": "Minimum value can not exceed maximum",
    "ESTIMATED_ANNUAL_CONSUMPTION": "Est. annual consumption",
    "MONTHLY_CONSUMPTION": "Monthly cosumption",
    "ADD": "Add",
    "SELECT_RATE": "Select rate",
    "SELECT_MODE": "Select mode",
    "POW_MIN": "Pow. min.",
    "POW_MAX": "Pow. max.",
    "PROFESSIONALS_AREA_ACCESS": "Professionals area access",
    "CUSTOMERS_AREA_ACCESS": "Customers area access",
    "RELATED_AGREEMENTS": "Related agreements",
    "SELECT": "Select",
    "EMPTY_LIST": "Empty list",
    "SUCCESS_UPDATED": "Fee successfully updated",
    "SYNCHRONIZATION_AUDINFOR": "Audinfor synchronization",
    "STATUS": "Status",
    "ACTIVE": "Active",
    "INACTIVE": "Inactive",
    "PENDING": "Pending",
    "AGREEMENT_CONFIGURATION": "Agreement configuration",
    "AGREEMENT": "Agreement",
    "INACTIVE_COMMISSIONING_CONTRACTS": "Inactive commissioning contracts",
    "INVALID_EMAIL": "Email format is not valid",
    "CODE_MANDATORY": "Code must be included",
    "INVALID_MIN_CONTRACTS": "Must be an Integer",
    "INVALID_MAX_DELAY_PAYMENT": "Must be an Integer",
    "INVALID_VAT_PERCENT": "Must be an Integer",
    "INVALID_WITHHOLDING_PERCENT": "Must be an Integer",
    "SUCCESS_AGREEMENT_UPDATED": "Agreement successfully updated",
    "ERROR_AGREEMENT_UPDATED": "Agreement can not be updated. Check out errors.",
    "ERROR_INVALID_MAIL": "Invalid email format",
    "NEW_AGREEMENT": "New Agreement",
    "AGREEMENT_CREATED_SUCCESSFULLY": "Agreement created successfully",
    "ERROR_AGREEMENT_CREATED": "Agreement can not be created. Check out errors.",
    "DOWNLOAD": "Download",
    "DOWNLOAD_CSV": "Download CSV",
    "DELETE_AGREEMENT": "Delete agreement?",
    "AGREEMENT_DELETED_SUCCESSFULLY": "Agreement deleted successfully",
    "VIEW": "View",
    "FEES": "Fees",
    "DATE": "Date",
    "COMMISSION": "Commission",
    "COMMISSION_CONSUMPTION": "€ / kWh",
    "CONSUMPTION": "Consumption",
    "FROM":"From",
    "TO":"To",
    "CONSUMPTION_BASED":"Consumption-based",
    "EURO/Kw": "€/kWh",
    "AGREEMENT_SURNAME_1": "Surname 1",
    "AGREEMENT_SURNAME_2": "Surname 2",
    "AGREEMENT_NUMBER": "Number",
    "AGREEMENT_EXT": "Extension",
    "RECEIVE_PAPER_INVOICES": "Receive paper invoices",
    "DEFAULT_BANK_ACCOUNT": "Default contract payment account",
    "CONTACT_INFORMATION": "Contact Information",
    "CONTACT_DATA": "Contact data",
    "CONTACT_ADDRESS": "Contact Address",
    "PRICE_DELETED": "Fee deleted successfully",
    "PRICE_CREATED": "Fee created successfully",
    "USER_ADDED": "User added successfully",
    "USER_DELETED": "User deleted successfully",
    "RELATED_AGREEMENT_ADDED": "Related agreement added successfully",
    "RELATED_AGREEMENT_DELETED": "Related agreement deleted successfully",
    "LOGS": "Logs",
    "OBSERVATIONS": "Observations",
    "STATUS_REQUIRED": "Status is required",
    "CONTRACT": "Contract",
    "COMMERCIAL_COMMENTS": "Commercial observations",
    "FILTER_BY_CONTRACT": "Filter by contract",
    "INVALID_CITY_VALUE": "Invalid city value",
    "Date range": "Dates",
    "Without Filter": "No Filter",
    "Date from": "From",
    "Date to": "To",
    "Activation date range": "Activation date",
    "Creation date range": "Creation date",
    "COPY_FROM_CONTACT": "Copy from Contact",
    "NOT_MANAGE_DATE": "Not manage date",
    "PAYMENT_NOTES": "Payment notes",
    "RENEWAL_EMAIL": "Renewal Email",
    "RENEWAL_EMPTY": "There is no emails in user web access",
    "TYPE": "Type",
    "NORMAL": "Normal",
    "RENTAL": "Rental or collaborator",
    "TYPE_REQUIRED": "Type is requiered",
    "DEBTS_EMAIL": "Email for debts",
    "POSITIVE_DAY_NUMBER_ERROR": "Must set a number between 1..31",
    "CSV_DOWNLOAD": "Download requested, please wait...",
    "CONFIRM_BULK_UPDATE": "The change will be performed in the selected agreements!"
  }
}
