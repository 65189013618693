const Report = ['$q', '$http',
    function ($q, $http) {

        var url = API_URL + '/api/1.0/reports/';
        var report = null;

        function getReports() {
            return _getReports();
        }

        function _getReports() {
            var deferred = $q.defer();

            $http.get(url)
                .then(function (report_data) {
                    deferred.resolve(report_data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function listReports(data) {
            return _listReports(data);
        }

        function _listReports(data) {
            var deferred = $q.defer();


            $http.post(url + 'list/', data)
                .then(function (report_data) {
                    deferred.resolve(report_data.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        function getReportDetail(id) {
            if (report == null || report.id != id) {
                report = {
                    'id': id
                };
                return _getReportDetail(id);
            } else {
                var deferred = $q.defer();
                deferred.resolve(report.data);

                return deferred.promise;
            }
        }

        function _getReportDetail(id) {
            var deferred = $q.defer();

            $http.get(url + id)
                .then(function (report_data) {
                    report = report_data;
                    deferred.resolve(report.data);
                })
                .catch(function (error) {
                    deferred.reject(error.data);
                });

            return deferred.promise;
        }

        return {
            listReports, listReports,
            getReports: getReports,
            get: getReportDetail
        };

    }
]

export { Report }
