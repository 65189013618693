{
  "INVOICES": {
    "TITLE": "Facturas",
    "TOTAL": "Total",
    "MINIMUM": "Mínimo",
    "MAXIMUM": "Máximo",
    "SEARCH": "Buscar...",
    "STATUS": "Estado",
    "ANY": "Cualquiera",
    "AGREEMENT": "Convenio",
    "STATE": "Provincia",
    "FROM": "Desde",
    "TO": "Hasta",
    "PAID": "Pagado",
    "UNPAID": "Impagado",
    "PENDING": "Pendiente",
    "INVOICE": "Factura",
    "INVOICE_DATA": "Información de la factura",
    "CREATION_DATE": "Fecha de creación",
    "BILLING_PERIOD": "Periodo de facturación",
    "EXPIRED": "Vencida",
    "INVOICE_LINES": "Lineas de factura",
    "ID": "ID",
    "TYPE": "Tipo",
    "UNIT_PRICE": "Precio unitario",
    "TEXT": "Texto",
    "RATE": "Rate",
    "NO_INVOICE": "Nº Factura",
    "NO_CONTRACT": "Nº Contrato",
    "ALL": "Todo",
    "NIF/CIF": "NIF/CIF",
    "CONTRACT": "Contrato",
    "DATE": "Fecha",
    "TOTAL_AMOUNT": "Importe total",
    "COMMENTS": "Comentarios",
    "FILES": "Archivos",
    "DOWNLOAD_EINVOICE": "Descargar facturaE",
    "Bulk download generation Task success": "La generación masiva de facturas electrónicas se ha puesto en cola",
    "Bulk download generation Task error": "Se ha producido un error en la generación de la tarea de descarga",
    "INVOICES_SERIES": "Series de Facturación",
    "Counter": "Contador",
    "Invoice number length": "Longitud",
    "EDIT": "Editar",
    "DELETE": "Eliminar",
    "DELETE_SERIES": "¿Desea borrar la serie?",
    "YES": "Sí",
    "NO": "No",
    "CODE": "Código",
    "SERIES": "Series",
    "NEW_SERIE": "Nueva serie",
    "Series code": "Código de la serie",
    "Series description": "Descripción de la serie",
    "Counter start": "Nº inicio contador",
    "Number length": "Lóngitud del número",
    "SAVE": "Salvar",
    "CANCEL": "Cancelar",
    "INVOICE_SERIES_CREATED_SUCCESSFULLY": "Serie creada correctamente",
    "INVOICE_SERIES_UPDATED_SUCCESSFULLY": "Serie actualizada correctamente",
    "INVOICE_SERIES_UPDATED_FAIL": "Error actualizando la serie",
    "BANKS": "Bancos",
    "CREDIT_LIMIT": "Limite de crédito",
    "EXPIRATION_DATE": "Dias de vencimiento",
    "BANK_DATA": "Datos del banco",
    "BANK_NAME": "Nombre del banco",
    "BANK_ACCOUNT": "Número de cuenta",
    "MOD_DATE": "Fecha de modificación",
    "BANK_UPDATED": "Datos de banco actualizados correctamente.",
    "BANK_UPDATED_ERROR": "Error actualizando los datos del banco.",
    "NEW_BANK": "Nuevo banco",
    "CREATE_BANK": "Banco creado correctamente.",
    "CREATE_BANK_ERROR": "Error al crear el banco.",
    "DELETE_BANK": "¿Eliminar banco?",
    "DELETE_BANK_OK": "Banco eliminado correctamente.",
    "DELETE_BANK_ERROR": "Error al eliminar el banco.",
    "NEW_INVOICE": "Nueva Factura",
    "EMITTER": "Emisor",
    "RECEIVER": "Receptor",
    "NIFCIF": "NIF/CIF",
    "NAME": "Nombre",
    "EMAIL": "Correo electrónico",
    "PHONE": "Teléfono",
    "MOBILE": "Móvil",
    "FAX": "Fax",
    "ADDRESS": "Dirección",
    "CITY": "Ciudad",
    "ZIP": "Código postal",
    "OBSERVATIONS": "Observaciones",
    "WEB": "Web",
    "DRAFT": "Borrador",
    "UPDATE_SUCCESS": "Factura editada correctamente",
    "UPDATE_ERROR": "Error al editar",
    "TOSAVE": "Guardar",
    "ACTIONS": "Acciones",
    "CREATE_REFUND": "Crear rectificativa",
    "SURE": "¿Estás seguro?",
    "REFUND_ERROR": "Error al crear factura rectificativa.",
    "SET_AS_SENT": "Marcar como enviado",
    "SENT": "Enviado",
    "ADD": "Añadir",
    "PRODUCT_CODE": "Código de producto",
    "CLOSE": "Cerrar",
    "QUANTITY": "Cantidad",
    "ERROR_UPDATING_INVOICE_LINES": "Error al actualizar las lineas de factura.",
    "UPDATED_INVOICE_LINES": "Lineas de factura actualizadas correctamente.",
    "Bulk download PDF": "La generación en PDF de facturas se ha puesto en cola",
    "TAX": "Impuesto",
    "CONTRACTS_INVOICE": "Facturar contratos",
    "MANUAL_INVOICE": "Factura manual",
    "TO_INVOICE": "Facturar",
    "DOWNLOAD_CSV": "Descargar CSV",
    "DOWNLOAD": "Descargar",
    "DOWNLOAD_PDF": "Descargar PDF",
    "Bulk download CSV": "Descarga de CSV en cola",
    "FEES": "Honorarios",
    "COMMISSION": "Comisión",
    "WITHOLDING": "IRPF",
    "LIST_FEES": "Listado de honorarios",
    "DELETEQ": "¿Eliminar",
    "ELEMENTSQ": "elementos?",
    "ALL_SELECTED": "Todos",
    "SET_AS_INVOICED": "Marcar como facturado",
    "SET": "Cambiar",
    "INVOICED": "Facturado",
    "VIEW": "Ver",
    "Date range": "Fechas",
    "Date from": "Desde",
    "Date to": "Hasta",
    "Without Filter": "No filtrar",
    "Creation date range": "Fecha de creación",
    "Billing period date range": "Periodo de facturación",
    "SET_AS_DRAFT": "Marcar como borrador",
    "SENT_DRAFT_OK": "Facturas enviadas a estado 'Borrador' correctamente. Número de Facturas actualizadas: ",
    "SENT_DRAFT_KO": "Error cambiado el estado.",
    "ONLY_PRINTABLES": "Sólo imprimibles",
    "NOT_PRINTABLES": "No imprimibiles",
    "PRINTABLE": "Imprimibles",
    "ADD_PAYMENT": "Añadir pago",
    "ADD_PAYMENT_TO_INVOICE": "Añadir pago a la factura: ",
    "BACK": "Volver",
    "PAYMENT_TYPE": "Tipo de pago",
    "AMOUNT_TO_PAY": "Cantidad a pagar",
    "ORIGIN": "Id de origen del pago",
    "TRANSFER": "Transferencia",
    "RECEIPT": "Recibo",
    "CREATED_PAYMENT_SUCCESS": "Pago creado con éxito",
    "CREATED_PAYMENT_ERROR": "Error al crear el pago",
    "RECEIPTS": "Recibos",
    "SENT_DEFINITIVE_OK": "Facturas enviadas a estado 'Facturado' correctamente. Comenzada la generación de PDFs. ",
    "SENT_DEFINITIVE_KO": "Error cambiado el estado.",
    "EXTRA_EXPENSES": "Gastos extra",
    "SENDING_DRAFT": "Enviando facturas a estado 'Borrador'.",
    "SENDING_SENT": "Enviando facturas a estado 'Enviado'.",
    "SENDING_DEFINITIVE": "Enviando facturas a estado 'Facturado'.",
    "SENT_SENT_OK": "Facturas enviadas a estado 'Enviado' correctamente. Número de Facturas actualizadas: ",
    "SENT_SENT_KO": "Error cambiado el estado.",
    "PAYMENTS": "Pagos",
    "Invoices PDFs generated": "PDFs de facturas generados",
    "Invoices PDFs not generated": "PDFs de facturas no generados",
    "CUSTOMER_NAME": "Nombre Cliente",
    "CHANGE_REMIT_DATE_OK": "Fecha de remesa actualizada",
    "CHANGE": "Cambiar",
    "CHANGE_REMIT_DATE": "Cambiar fecha de remesa",
    "CHANGE_REMIT_DATE_OF": "Cambiar fecha de remesa de",
    "REMIT_DATE": "Fecha de remesa",
    "UNPAID_DATE": "Fecha de impago",
    "PAID_DATE": "Fecha de pago",
    "DELETED": "Borradas ",
    "TO_DEFINITIVE_NUMBER": "Número de Facturas actualizadas: ",
    "RELATED": "Factura relacionada",
    "REMITTABLE": "Remesable",
    "NOT_REMITTABLE": "No remesable",
    "REMITTABLES": "Remesables",
    "NOT_REMITTABLES": "No remesables",
    "SET_AS_PAID": "Marcar como pagado",
    "ORIGIN_PAID": "Origen del pago",
    "PAY": "Pagar",
    "ORIGIN_PAY": "Origen: ",
    "CON_TYPE": "Consumo",
    "F11_TYPE": "XML F101",
    "SENT_PAID_OK": "Facturas marcadas como 'Pagadas'. Número de facturas pagadas: ",
    "SENT_PAID_KO": "Error marcando las facturas como pagadas.",
    "DELETE_PAYMENT_MESSAGE": "¿Está seguro?",
    "DELETE_PAYMENT_OK": "Pago eliminado correctamente.",
    "EXPENSES": "Gastos",
    "INVOICED_DATE": "Fecha de Facturación",
    "DEFERMENT": "Aplazamiento",
    "IDN_REPEATED": "El IDN está repetido",
    "ADD_RECEIPT": "Añadir recibo",
    "ADD_RECEIPT_TO_INVOICE": "Añadir recibo manual a la factura: ",
    "AMOUNT": "Importe",
    "REASON": "Razón",
    "CREATED_RECEIPT_SUCCESS": "Recibo creado con éxito",
    "CREATED_RECEIPT_ERROR": "Error al crear el recibo",
    "MANUAL": "Manual",
    "SEND_EINVOICE": "Enviar factura-e",
    "SENDQ": "¿Enviar",
    "INVOICESQ": "facturas?",
    "SENDING_EINVOICES": "Enviando facturas electrónicas.",
    "ORIGIN_TYPE": "Tipo Origen",
    "RETURNED": "Alguna vez devuelta",
    "POSTAL_DATA": "Datos postales",
    "SUPPLY_DATA": "Datos punto suministro",
    "SEPA": "SEPA",
    "DOWNLOAD_INDEXED_INVOICE_CSV_DATA": "Datos de indexada",
    "PAYMENT_ORIGIN": "Origen: ",
    "HANDMADE": "Editada manualmente",
    "SUPPY_CITY_CODE": "Código de Municipio",
    "NEW": "Crear",
    "DOMICILED": "Domiciliado",
    "REQUEST_DELETED": "Realizando el proceso de eliminación de facturas. Puede que lleve unos segundos.",
    "DELETED_INVOICE_ERROR": "Se ha producido un error",
    "AGREEMENT_SEARCH": "Incluir Subconvenios",
    "AGREEMENT_PARTIAL_SEARCH": "Sí",
    "AGREEMENT_TOTAL_SEARCH": "No",
    "ORIGIN_TOO_LARGE": "El límite de origen del pago es 75 caracteres.",
    "APPLY": "Aplicar",
    "UPDATE_CONTRACT_DATA": "Actualizar datos de contrato",
    "UPDATE_CONTRACT_DATA_WARNING": "Los datos del contrato seleccionado no se guardarán hasta que no se guarde la factura",
    "NORMAL_INVOICE": "Normal",
    "INDEXED_INVOICE": "Indexado",
    "INDEXED_INVOICE_WITHOUT_ELEVATION": "Indexado sin elevación a barras",
    "INDEXED_INVOICE_MIXED": "Indexada mixta",
    "INVOICING_MODE": "Modo facturación",
    "PAYMENT_METHOD": "Forma de pago",
    "DEBIT": "Domiciliado",
    "OVERVIEW_INVOICE": "Factura Resumen",
    "CONTRACT_ID": "Contrato",
    "START_DATE": "Fecha de inicio",
    "FINISH_DATE": "Fecha de fin",
    "EXTRA_1": "Columna extra 1",
    "EXTRA_2": "Columna extra 2",
    "CLEAN_CONTRACT_DATA": "Limpiar datos de contrato",
    "SHOW_INVOICES_IN_OBSERVATIONS": "Mostrar facturas en las observaciones",
    "SHOW_DEBT_INVOICES": "Mostrar sólo facturas con clientes en morosidad",
    "HIDE_DEBT_INVOICES": "Excluir facturas con clientes en morosidad",
    "Bulk set to invoiced Task success": "La tarea marcar Facturas como facturado se ha puesto en cola",
    "Bulk set to invoiced Task error": "Se ha producido un error en la tarea de paso de facturas a facturado",
    "DOWNLOAD_EINVOICE_WITHOUT_PDF": "Descargar facturaE (sin PDF)",
    "SEND_EINVOICE_WITHOUT_PDF": "Enviar factura-e (sin PDF)",
    "FACE_COMPATIBLE": "Compatible con FACe",
    "CUSTOM_PDF": "PDF personalizado",
    "CUSTOM_TEXT": "Texto personalizado",
    "Generating_overview_invoice": "Generando Factura Resumen",
    "Generating_overview_invoice_error": "Error Generando Factura Resumen",
    "Custom_invoice_pdf": "Generando facturas PDF personalizadas",
    "Custom_invoice_pdf_error": "Error Generando facturas PDF personalizadas",
    "WITH_SIGNATURE": "Con firma y sello",
    "CUSTOMER_INVOICES": "Facturas de Clientes",
    "CUSTOMER_INVOICE": "Factura de Cliente",
    "ITEM_ORIGIN": "Origen",
    "ITEM_ORIGIN_ID": "ID",
    "VALUE": "Valor",
    "ITEM_TO_BE_INVOICED": "Obj. Facturación",
    "ESTIMATED_INVOICE": "Estimada",
    "Q101": "Peaje directo",
    "TO_UPDATE": "Vas a modificar",
    "SET_CONTRACT_DATA": "Cargar datos de Contrato",
    "INVOICES_NUMBER": "Nº de Factura",
    "REFUND": "Abono",
    "REFUND_WITH_REISSUE": "Abono con refactura",
    "ONLY_B": "(Solo B)",
    "ONLY_A": "(Tipo A)",
    "B_+_R": "(B + R)",
    "REISSUE": "Rectificativa",
    "ESTIMATED": "Estimada",
    "COMPENSATORY_UNPAID": "Compensatoria",
    "INVOICE_UNPAID": "Facturas impago",
    "Generating_overview_unpaid_invoices": "Generación facturas de impagados",
    "Generating_overview_unpaid_invoices_error": "Error generación facturas de impagados",
    "COMPENSATED": "Compensada",
    "UNPAID_ADJUSTMENT": "Ajuste por impago",
    "REISSUE_UNPAID_ADJUSTMENT": "R. ajuste por impago",
    "CREDIT_RETURNED_CUSTOMER": "Abono devuelto por el cliente",
    "SET_REMITTABLE": "Fijar como remesable/s",
    "SET_REMITTABLE_OPTIONS": "Permitir remesar facturas",
    "Bulk remittable CSV": "Cambio masivo remesables satisfactorio",
    "CONFIRM_BULK_UPDATE": "¡Estos datos de actualizarán en todas las facturas seleccionadas!",
    "SET_AS_UNPAID": "Marcar como impagado",
    "SENT_UNPAID_OK": "Facturas marcadas como 'Impagadas'. Nº de facturas actualizadas: ",
    "SENT_UNPAID_KO": "Error marcando las facturas como impagadas.",
    "SENT_UNPAID_NOT_UPDATE": "Nº Facturas no actualizadas: ",
    "SENT_UNPAID_NOT_UPDATE_IDS": "IDs no actualizados: ",
    "CLOSE_ESTIMATED_TYPE": "Cierre",
    "FACE_INVOICES": "Facturas de FACE",
    "FACE_SENT_INVOICES": "Facturas enviadas a FACE",
    "FACE_SENT_STATUS_INVOICES": "Estado factura en FACE",
    "FACE_PAID": "Pagada",
    "OTHER_FACE_STATUS": "Otros",
    "FACE_REGISTER": "Registrada",
    "FACE_REGISTER_RCF": "Registrada en RCF",
    "FACE_REJECT": "Rechazada",
    "FACE_COUNTED": "Contabilizada la obligación reconocida",
    "HIDE_WEB": "Ocultar factura en la web",
    "HIDDEN_WEB": "Ocultas en la web",
    "DEBIT_B2B": "Domiciliado B2B",
    "REGULARISED": "Regularizadora",
    "COVER": "Caratula",
    "ADD_COMMENT": "Añadir comentario/s",
    "COMMENT": "Comentario",
    "Bulk_Comment": "Añadidos comentarios satisfactoriamente",
    "COMPENSATE_INVOICES": "Compensar facturas",
    "MASSIVE_COMPENSATION": "Añadida tarea de compensación de facturas",
    "MASSIVE_COMPENSATION_ERROR": "Error en tarea de compensación de facturas",
    "PRINT_INVOICE": "Imprimir factura",
    "MASSIVE_PRINT_INVOICES": "Imprimir facturas masivamente",
    "PRINT_INVOICES": "Imprimir facturas",
    "MASSIVE_PRINT_INVOICES_ERROR": "Error imprimir facturas masivamente",
    "RECONCILED": "Conciliada",
    "NOT_RECONCILED": "No Conciliada",
    "SET_RECONCILED": "Marcar Como Conciliadas",
    "COMPENSATION": "Compensación",
    "SELF_CONSUMPTION": "Autoconsumo",
    "METER_ID": "Contador",
    "PERIOD": "Periodo",
    "DATE_PERIOD": "Fecha inicio - fin lectura",
    "ACTIVE_PREVIOUS": "EA Anterior",
    "ACTIVE_CURRENT": "EA Actual",
    "ACTIVE_CONSUMPTION":  "EA Consumo",
    "REACTIVE_PREVIOUS": "RE Anterior",
    "REACTIVE_CURRENT":  "RE Actual",
    "REACTIVE_CONSUMPTION": "RE Consumo",
    "MAXIMETER": "Maximetro",
    "INVOICE_READINGS": "Lecturas",
    "ELECTRICITY_METER_READINGS": "Lecturas de contador del pdf de la factura",
    "REMITTANCE": "Remesa",
    "REFUND_PAYMENT": "Abono",
    "INVOICE_PAYMENT_TYPE": "Tipo Pago",
    "DOWNLOAD_REMITTABLE_REPORT": "Descarga Informe Remesables",
    "ESTIMATED_BALANCING": "Est. Regularizadora",
    "SET_HIDDEN": "Marcar Como Ocultas en la Web",
    "CREATE_MANUAL_INVOICE": "Factura manual creada correctamente.",
    "GENERATE_ESTIMATED_TASK_CREATED": "Creada la tarea de estimación en bloque",
    "GENERATE_ESTIMATED_TASK_ERROR": "La tarea de estimación en bloque ha fallado",
    "BULK_DELETE_INVOICES_SUCCESS": "La tarea Eliminar facturas se ha puesto en cola",
    "BULK_DELETE_INVOICES_ERROR": "Se ha producido un error en la tarea Eliminar facturas",
    "DETAIL_PREVENT_BACK": "Se perderán los cambios sin guardar.\n¿Deseas continuar?",
    "DOWNLOAD_INVOICE_LINES": "Descargar líneas de factura",
    "GENERATE_REFUNDS": "Generar Abonos",
    "GENERATE_REFUNDSQ": "Facturas a abonar: ",
    "SINGLE_INVOICE": "Factura única",
    "BULK_REFUND_INVOICES_SUCCESS": "La tarea Abonar Facturas se ha puesto en cola",
    "BULK_REFUND_INVOICES_ERROR": "Se ha producido un error en la tarea Abonar Facturas. Recarga la página y selecciona las facturas de nuevo.",
    "ORIGIN_ID": "ID Origen",
    "SEND_TO_SII": "Enviar a SII",
    "Bulk_Send_to_sii": "Creada la tarea de envío a SII",
    "Bulk_Send_to_sii_error": "Error al crear la tarea de envío a SII",
    "Company": "Compañía",
    "PREFIX": "Prefijo",
    "FUTURE_ALLOWED": "A futuro",
    "FAILED": "Fallido",
    "DUPLICATE_INVOICE": "Duplicar factura",
    "DUPLICATE_ERROR": "Error al duplicar factura.",
    "CUPS": "CUPS",
    "BULK_DUPLICATE_INVOICES_SUCCESS": "La tarea Duplicar facturas se ha puesto en cola",
    "BULK_DUPLICATE_INVOICES_ERROR": "Se ha producido un error en la tarea Duplicar facturas",
    "DEBIT_GBT":  "Domiciliado Agrupado",
    "FLAT_RATE": "Tarifa Plana",
    "REFUND_NEW_PRICES": "Refacturar con Precios Actuales",
    "BULK_REFUND_INVOICES_NEW_PRICES_SUCCESS": "La tarea de Refacturar facturas con nuevos Precios se ha puesto en cola",
    "BULK_REFUND_INVOICES_NEW_PRICES_ERROR": "Se ha producido un erro em la tarea Refacturar con nuevos Precios",
    "REFUND_RECEIPT_BANK_ACCOUNT": "Abonar recibo factura cambio de cuenta bancaria",
    "REFUND_RECEIPT_BANK_ACCOUNT_ERROR": "Error en abonar recibo factura cambio de cuenta bancaria",
    "MASSIVE_REFUND_CHANGE_BANK_ACCOUNT_RECEIPT": "Cambio masivo cuenta bancaria en recibo y devolución existente",
    "CHANGE_REMIT_DATE_OK_TASK": "Tarea de actualización de fecha de remesa lanzada con éxito",
    "CHANGE_REMIT_DATE_OK_TASK_ERROR": "Ha habido algún error al lanzar la tarea de cambio de fecha de remesa"
  }
}
