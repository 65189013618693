const ListSuppliesCtrl = ['$scope','$sce', '$filter', 'Task', 'Alert', '$window', 'SUPPLY_RATES', 'ProgrammingUnitsMaster', 'UserInfo', 'CitiesMaster', 'filtersUtils',
    function ($scope, $sce, $filter, Task, Alert, $window, SUPPLY_RATES, ProgrammingUnitsMaster, UserInfo, CitiesMaster, filtersUtils) {
        /***********************************************************************************************************
         * Controller Attributes
         ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.SUPPLIES_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown.html');
        $scope.SUPPLIES_DOWNLOAD_DROPDOWN = require('../../templates/download-dropdown.html');

        /***********************************************************************************************************
         * Scope Attributes
         ***********************************************************************************************************/
        $scope.searchBy = 'search';
        $scope.searchByLabel = $translate('SUPPLIES.ALL');
        $scope.itemsToDownload = [];
        $scope.backgroundTasks = [];
        $scope.searchFilters = {
            'cups': $translate('SUPPLIES.CUPS'),
            'contract_id': $translate('CONTRACTS.CONTRACT_NUM'),
            'vat_id': $translate('CONTRACTS.Vat id'),
            'fullname': $translate('SUPPLIES.SUPPLY_FULLNAME'),
            'connection_number': $translate('SUPPLIES.CONNECTION_NUMBER'),
            'search': $translate('SUPPLIES.ALL')
        };

        $scope.searchBy = Object.keys($scope.searchFilters)[0];
        $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];
        $scope.results = [];
        $scope.primaryKey = 'cups';

        $scope.statuses = [
            {code: 'ACT', name: $translate('SUPPLIES.ACTIVE')},
            {code: 'INA', name: $translate('SUPPLIES.INACTIVE')}
        ];

        $scope.rates = SUPPLY_RATES;
        $scope.ordering = {
            "cups": $translate('SUPPLIES.CUPS'),
            "status": $translate('SUPPLIES.STATUS'),
            "rate": $translate('SUPPLIES.TOLL_RATE'),
            "state": $translate('SUPPLIES.STATE')
        };

        $scope.filters = {
            search: "",
            id: "",
            cups: "",
            status: "ACT",
            rate: null,
            state: null,
            fullname: "",
            vat_id: "",
            city: "",
            programming_unit: null,
            auto_consumption_type: null,
            labels: ""
        };

        $scope.update_list = {};

        /***********************************************************************************************************
         * Scope Watchers
         ***********************************************************************************************************/
        $scope.$watch('searchQuery', function (newVal, oldVal) {
            $scope.filters[$scope.searchBy] = newVal;
        });

        $scope.$watch('noProgrammingUnits', function (newValue, oldValue) {
            if (newValue) {
                $scope.filters['programming_unit'] = null;
            }
        });

        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
         * Controller Methods
         ***********************************************************************************************************/

        /***********************************************************************************************************
         * Scope Methods
         ***********************************************************************************************************/

        $scope.canSelectSuppliesForActions = function () {
            return this.can_set_provider_invoice();
        };

        $scope.can_set_provider_invoice = function () {
            return (UserInfo.hasPerm("programming_units.set_programming_unit_to_supplies"));
        };

        $scope.can_add_calendar_for_supplies = function () {
            return (UserInfo.hasPerm("calendars.add_calendarforsupply"));
        };

        $scope.can_delete_calendar_for_supplies = function () {
            return (UserInfo.hasPerm("calendars.delete_calendarforsupply"));
        };

        $scope.can_download_supplies_list = function () {
            return (UserInfo.hasPerm("supplies.list_supplies"));
        };

        $scope.canAssignLabel = function () {
            return UserInfo.hasPerm('labels.add_labelledobject');
        };

        $scope.canDeleteAssignLabel = function () {
            return UserInfo.hasPerm('labels.delete_labelledobject');
        };

        $scope.setSearchBy = function (val) {
            $scope.filters[$scope.searchBy] = '';
            $scope.searchBy = val;
            $scope.searchByLabel = $scope.searchFilters[val];
            $scope.filters[val] = $scope.searchQuery;
        };

        $scope.getStyles = function (result) {
            var styles = '';
            if (result.status == 'ACT')
                styles += ' ' + 'success';
            if (result.status == 'INA')
                styles += ' ' + 'border';

            return styles;
        };

        $scope.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownload(elements);
            return params;
        };

        $scope.downloadExcel = function () {
            if ($scope.counter !== 0) {
                var task = new Task();
                task.type = 'SCSV';
                task.params = $scope.getJSONParams($scope.itemsToDownload);
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('SUPPLIES.Bulk download CSV'), true);
                    $scope.backgroundTasks.push(data.id);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                });
            }
        };

        $scope.massive_labels_supplies = function () {
            if ($scope.counter !== 0) {
                var task = new Task();
                task.type = 'LMAT';
                $scope.update_list.ids = $scope.getJSONParams($scope.itemsToDownload).ids;
                $scope.update_list.scope = 'supplies'
                task.params = $scope.update_list;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('LABELS.MASSIVE_LABELS'), true);
                    $scope.update_list.mode = ''
                    $scope.update_list.label = '';
                    $scope.update_list.comment_massive_label = '';
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('LABELS.MASSIVE_LABELS_ERROR'), true);
                    $scope.update_list.mode = '';
                    $scope.update_list.label = '';
                    $scope.update_list.comment_massive_label = '';
                });
            }
        };

        /***********************************************************************************************************
         * Controller Initialization
         ***********************************************************************************************************/
        ProgrammingUnitsMaster.loadProgrammingUnits().then(function (response) {
            $scope.programming_units = response;
        });

        CitiesMaster.loadCities().then(function (response) {
            $scope.cities = response;
        });
}]

export {ListSuppliesCtrl};
