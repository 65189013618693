{
  "SUPPLIES": {
    "TITLE": "Suministros",
    "STATE": "Província",
    "ANY": "Cualquiera",
    "STATUS": "Estado",
    "RATE": "Tarifa",
    "CUPS": "CUPS",
    "SUPPLY_POINT": "Punto de suministro",
    "REPORTS":"Informes",
    "CONSUMPTION_REPORT_AVERAGE":"Informe de medias de consumo",
    "CONSUMPTION_REPORT":"Informe de consumo",
    "COMPARATIVE_REPORT": "Informe comparativo",
    "COMPARATIVE_REPORT_AVERAGE": "Informe comparativo de medias",
    "COMMENTS": "Comentarios",
    "FILES": "Archivos",
    "DATA_SUPPLY": "Datos del suministro",
    "APC": "APC",
    "CONTRACTED_POWER": "Potencia contratada",
    "OWNER_ADDRESS_SUPPLY": "Titular y dirección de suministro",
    "ADDRESS": "Dirección",
    "TOWN": "Población",
    "ZIP_CODE": "Código postal",
    "HOLDER_SUPPLY": "Titular del suministro",
    "FROM": "Desde",
    "TO": "Hasta",
    "GENERATE_REPORT": "Generar informe",
    "ANY_RATE": "Cualquier tarifa",
    "EXPORT_CSV": "Exportar a CSV (Excel)",
    "DEVIATION_HIGHLIGHT": "Desviación a destacar",
    "NO_RESULTS": "No hay resultados para los datos indicados",
    "TRY_OTHER_DATE": "Intenta utilizar otros criterios para el informe",
    "ERROR": "Se ha producido un error",
    "CUPS_FIRST_DATE": "Cups asociados a la primera fecha",
    "CUPS_SECOND_DATE": "Cups asociados a la segunda fecha",
    "SHOW_CUPS": "Ver listado de CUPS asociados al informe",
    "HIDE_CUPS": "Ocultar listado de CUPS asociados al informe",
    "HISTORY_IMPORTS": "Histórico de importaciones",
    "NEW_IMPORT": "Nueva importación",
    "SECONDARY_HUB": "Informe de consumos",
    "PENDING": "Pendiente",
    "UNDOING": "Deshaciendo",
    "DOING": "Ejecutando",
    "UNDO": "Undo",
    "CANCELED": "Cancelada",
    "FINISHED": "Finalizada",
    "DISCARDED": "Deshecha",
    "IMPORT_DATA": "Importación de datos de concentrador secundario",
    "CREATED": "creados",
    "EXISTENT": "existentes",
    "INCIDENCE": "incidencia",
    "INCIDENCES": "incidencias",
    "IMPORT_CONSUMPTIONS": "Importar consumos",
    "TOLL_RATE": "Tarifa de peaje",
    "ALL": "Todo",
    "ACTIVE": "Activo",
    "INACTIVE": "Inactivo",
    "IMPORT_BACKGROUND": "La importación se está ejecutando en segundo plano",
    "ERROR_SENDING_TASK": "Se ha producido un error al enviar la tarea",
    "IMPORT_FINISHED": "Importación finalizada",
    "LOADING": "Cargando...",
    "Load SIPS": "Cargar SIPS",
    "Load": "Cargar",
    "CONTRACTS_FOR_SUPPLY": "Contratos",
    "EXPORT_CSV_DAY": "Exportar importe diario a CSV",
    "READINGS": "Lecturas",
    "START_DATE": "Fecha inicio",
    "FINISH_DATE": "Fecha fin",
    "REACTIVE_ENERGY": "Energia reactiva",
    "ACTIVE_ENERGY": "Energia activa",
    "DEMAND_POWER": "Potencia demandada",
    "POSITIVE_NUMBER_ERROR": "Debe introducir un número positivo",
    "NUMBER_ERROR": "Debe introducir un número",
    "DATE_ERROR": "Debe indicar una fecha",
    "CONSUMPTION_READINNG_CREATED": "Lectura de consumo creada con éxito",
    "CONSUMPTION_READINNG_ERROR": "Existen errores en la lectura de consumo",
    "ANY_SOURCE": "Cualquier fuente",
    "ACTIVE_CONTRACT": "Este punto de suministro tiene un contrato activo asignado ",
    "LIST_CONSUMPTION": "Consumos",
    "SEARCH_BY_SOURCE": "Buscar por origen",
    "DEFAULT_SOURCE": "Cualquiera",
    "DATE": "Fecha",
    "TIME": "Duracion",
    "VALUE": "Valor",
    "PERIOD": "Periodo",
    "SOURCE": "Origen",
    "WINTER": "Invierno",
    "SUMMER": "Verano",
    "ADD_READING": "Añadir lectura",
    "CLOSE": "Cerrar",
    "ELECTRICITY_METER": "Contador",
    "DATA_ELECTRICITY_METER": "Datos del contador",
    "SELECT_ELECTRICITY_METER": "Selecciona un contador",
    "NO_LAST_READING": "No hay ninguna lectura anterior.",
    "LAST_READING_MANUAL": "La última lectura es manual.",
    "TO_DATE_GREATER_FROM_DATE": "'Fecha desde' debe ser menor que 'Fecha hasta'.",
    "Double consumption report generation Task success": "Generación del informe comparativo de medidas puesto en cola correctamente",
    "Double consumption report generation Task error": "Error al poner en cola la generación del informe comparativo de medidas",
    "Daily consumption report generation Task success": "Generación del informe diario de consumos puesto en cola",
    "Daily consumption report generation Task error": "Error al poner en cola la generación del informe diario de consumos",
    "Single consumption report generation Task success": "Generación del informe de consumos puesta en cola",
    "Single consumption report generation Task error": "Error al poner en cola la generación de informe de consumos",
    "VIEW": "Ver",
    "TYPE": "Tipo",
    "TYPE_UPDATED": "Tipo de punto de suminstro actualizado correctamente.",
    "TYPE_UPDATED_ERROR": "Error actualizando el punto de suministro.",
    "MAGNITUDE": "Magnitude",
    "TERM": "Termino",
    "ORIGIN_READING": "Lectura origen",
    "FINAL_READING": "Lectura fin",
    "READING_UPDATED_OK": "Lectura actualizada correctamente",
    "CITY": "Ciudad",
    "SUPPLY_INFO_RATE_POWER": "Información Tarifa / Potencia contratada",
    "MAX_AUTH_POWER": "Max. potencia autorizada BIE",
    "VOLTAGE": "Voltage",
    "ACCESS_RIGHTS": "Derechos de acceso",
    "EXTENSION_RIGHTS": "Derechos de extensión",
    "CONSUMPTION_PROFILE_TYPE": "Tipo de perfil de consumo",
    "CONTRACT_INFO": "Información del contrato",
    "VAT_ID" : "NIF/CIF",
    "CONTRACT_START_DATE": "Fecha inicio contrato",
    "LAST_TRADING_DATE": "Fecha último cambio comercio",
    "LAST_RECRUITMENT_DATE": "Fecha último recrutamiento",
    "LAST_READING_DATE": "Fecha última lectura",
    "OTHERS": "Otros",
    "USUAL_HOUSING": "Vivienda habitual",
    "NT_CODE": "Código nt",
    "ICP_INSTALLED": "ICP instalado",
    "DEPOSIT_WARRANTY": "Depósito de garantía",
    "OWNS_MEASUREMENT_DEVICE": "Propietario disp. medición",
    "OWNS_ICP": "Propietario del ICP",
    "PS_CLASSIFICATION": "Clasificación PS",
    "NON_PAYMENT": "No pago",
    "CONSUMPTIONS": "Consumos",
    "CUPS_NPT_FOUND": "Cups no encontrado",
    "FORCE_READING": "Forzar lectura",
    "START_DATE_READING": "Fecha inicio lectura",
    "FINISH_DATE_READING": "Fecha fin lectura",
    "ELECTRICITY_METERS": "Contadores",
    "SERIAL_NUMBER": "Número de serie",
    "BRAND": "Marca",
    "NUMBER_PERIODS": "Discriminación horaria",
    "ADD_ELECTRICITY_METER": "Añadir contador",
    "CREATE": "Crear",
    "ELECTRICITY_METER_CREATED_OK": "Contador creado correctamente",
    "NO_ELECTRICITY_METERS": "No existe ningun contador. Cree uno para poder realizar operaciones sobre las lecturas.",
    "SUPPLY_FULLNAME": "Nombre",
    "SUPPLY_SURNAME_1": "Apellido 1",
    "SUPPLY_SURNAME_2": "Apellido 2",
    "CALENDARS_FOR_SUPPLY": "Calendarios",
    "NEW_CALENDAR_SUPPLY": "Añadir calendario al pto. suministro",
    "ADD_CALENDAR_FOR_SUPPLY": "Añadir",
    "CANCEL": "Cancelar",
    "CALENDAR_FOR_SUPPLY_ADDED": "Calendario añadido al punto de suministro.",
    "DELETE_CALENDAR_FOR_SUPPLY": "Eliminar Calendario del Pto. Suministro",
    "YES": "Sí",
    "NO": "No",
    "CONFIRM_DELETE_CALENDAR_FOR_SUPPLY": "¿Desea realmente desvincular el calendario del pto. suministro?",
    "CALENDAR_FOR_SUPPLY_DELETED": "Calendario desvinculado del Pto. sumninistro con éxito.",
    "COMMERCIAL": "Comercial",
    "DISTRIBUTOR": "Distribuidor",
    "UPDATE_SUPPLY": "Actualizar punto de suministro",
    "CONFIRM_UPDATE_SUPPLY": "A continuación debe introducir el motivo por el cual va a modificar el punto de suministro.",
    "NOT_IN_SIPS": "El punto de suministro no existe en el SIPS.",
    "DRAFT_CONTRACT": "Este punto de suministro tiene un borrador activo asignado ",
    "INACTIVE_CONTRACT": "Este punto de suministro tiene un contrato de baja asignado ",
    "commercial_id" : "Código Comercializadora",
    "remote_management_active" : "Telegestión",
    "electricity_meter_stage" : "Fases E. Medida",
    "self_consumption" : "Autoconsumo",
    "contract_type" : "Código Tipo Contrato",
    "invoicing_period" : "Código Periodicidad Facturación",
    "bie" : "Código BIE",
    "bie_start_date" : "Fecha Emisión BIE",
    "bie_finish_date" : "Fecha Caducidad BIE",
    "apm" : "Código APM",
    "apm_start_date" : "Fecha Emisión APM",
    "apm_finish_date" : "Fecha Caducidad APM",
    "intensity_transformation_relationship" : "Relación Transformacion/Intensidad",
    "cnae" : "CNAE",
    "power_control_mode" : "Código Modo Control Potencia",
    "cgp_power" : "Potencia CGPW",
    "electricity_meter_dh" : "Código DH E. Medida",
    "electricity_meter_accessibility" : "Código Accesibilidad Contador",
    "supply_availability" : "PS Contratable",
    "supply_availability_reason" : "Motivo Estado No Contratable",
    "measured_voltage" : "Código Tension Medida",
    "inquiry" : "Código Clase Expediente",
    "inquiry_reason" : "Código Motivo Expediente",
    "supply_type" : "Código Tipo Suministro",
    "PROGRAMMING_UNITS": "Unidades Programación",
    "ACTIONS": "Acciones",
    "LINK_PROGRAMMING_UNITS": "Vincular a Unidad de Programación",
    "UNLINK_PROGRAMMING_UNITS": "Desvincular de Unidad de Programación",
    "READING_ORIGIN": "Origen",
    "DOWNLOAD_REPORT": "Descargar Informe",
    "ACTIVE_CONSUMPTIONS": "Consumos Energía Activa",
    "REACTIVE_CONSUMPTIONS": "Consumos Energía Reactiva",
    "SINGLE-PHASE": "Monofásico",
    "TRIPHASE": "Trifásico",
    "GENERATE_ISSUE": "Generar envento",
    "INVALID_CITY_VALUE": "La población no es válida",
    "TOWN_MANDATORY": "La población es obligatoria",
    "NAME_REQUIRED": "El nombre es obligatorio",
    "ZIP_REQUIRED": "El código postal es obligatorio",
    "BT_MEASURE_LOSS": "BT con pérdidas",
    "CONNECTION_NUMBER": "Nº expediente acometida",
    "DOWNLOAD": "Descargar",
    "Bulk download CSV": "Descarga masiva en CSV",
    "ENERGY_ORIGIN": "Origen de la energía",
    "AUTO_CONSUMPTIONS": "Tipo de Autoconsumo",
    "AUTO_CONSUMTION": "Autoconsumo",
    "CIE": "CIE",
    "CIE_CODE": "Código CIE",
    "CIE_VOLTAGE": "Tensión",
    "CIE_MAX_POWER": "Potencia",
    "CIE_INSTALLER_CODE": "Código/NIF Instalador",
    "CIE_INSTALLER_NAME": "Nombre Instalador",
    "CIE_ISSUED_ON": "Fecha de emisión",
    "CIE_ACTIVATED_ON": "Fecha de activación",
    "CIE_FINISHED_ON": "Fecha de fin",
    "REGISTRY": "Referencia Catastral",
    "ATR_REQUEST_LIST": "Listado de procesos ATR para el cups: ",
    "YEARLY_CONSUMPTION": "Consumo anual",
    "YEARLY_CONSUMPTION_FROM": "Promedio anual entre ",
    "YEARLY_CONSUMPTION_TO": " y ",
    "CONFIRM_BULK_UPDATE": "¡Estos datos de actualizarán en todos los puntos de suministro seleccionados!"
  },
  "RECORDS": {
		"BUSINESS_RECORD_ADDED": "Expediente comercial añadido",
		"ERROR_ADDING_RECORD": "Error al añadir expediente",
		"CONTACTS": "Contactos",
		"NEW_BUSINESS_RECORD": "Nuevo expediente comercial",
		"NAME": "Nombre",
		"CODE": "Código",
		"SAVE": "Guardar",
		"CANCEL": "Cancelar",
		"SEE": "Ver",
		"DATA": "Datos",
		"BUSINESS_RECORD_DATA": "Datos de expediente comercial",
		"COMMENTS": "Comentarios",
		"FILES": "Archivos",
		"UPDATE": "Actualizar",
		"NAME_MANDATORY": "El campo 'name' es obligatorio",
		"CODE_MANDATORY": "El campo 'code' es obligatorio",
		"BUSINESS_RECORD_UPDATED": "Expediente comercial actualizado",
		"ERROR_UPDATING_RECORD": "Error al actualizar",
		"DELETE": "Eliminar",
		"YES": "Sí",
		"NO": "No",
		"COMMERCIAL_OFFERS": "Ofertas Comerciales",
		"COMMERCIAL_OFFER": "Oferta Comercial",
		"SEARCH": "Buscar...",
		"ALL": "Todo",
		"EDIT": "Editar",
		"DELETE_COMMERCIAL_OFFER": "¿Eliminar tarifa de precios?",
		"COMMERCIAL_OFFER_CREATED_SUCCESSFULLY": "Tarifa de precios creada con éxito.",
		"COMMERCIAL_OFFER_CREATED_ERROR": "No se ha creado la tarifa de precios. ",
		"COMMERCIAL_OFFER_CODE_ERROR": "El código indicado para la tarifa de precios ya existe.",
		"ERROR_OCCURRED": "Ha ocurrido un error al intentar crear la oferta comercial.",
		"COMMERCIAL_OFFER_DATA": "Datos de la tarifa de precios comercial",
		"SUCCESS_COMMERCIAL_OFFER_UPDATED": "Tarifa de precios actualizada con éxito.",
		"ERROR_COMMERCIAL_OFFER_UPDATED": "Se ha producido un error. No se ha podido actualizar la tarifa de precios.",
		"PRICES": "Tarifas de Precios",
		"NEW_PRICE": "Nueva tarifa de precios",
		"SELECT_RATE": "Seleccionar la tarifa: ",
		"ANY_RATE": "Seleccionar Tarifa",
		"POWER": "Potencia",
		"ACTIVE_ENERGY": "Energía activa",
		"NOT_PRICE_OFFER": "No existen tarifas de precios para esta oferta",
		"OFFER_PRICE_DELETED": "Tarifa de Precios de oferta comercial eliminada con éxito.",
		"OFFER_PRICE_UPDATED": "Tarifa de Precios de oferta comercial actualizada con éxito.",
		"BUSINESS_RECORD_DELETED": "Expediente comercial actualizado",
		"ERROR_DELETING_RECORD": "Error al eliminar expediente",
		"TABLE_P1": "P1",
		"TABLE_P2": "P2",
		"TABLE_P3": "P3",
		"TABLE_P4": "P4",
		"TABLE_P5": "P5",
		"TABLE_P6": "P6",
		"SELECT_COMMERCIAL_OFFER": "Selecciona oferta comercial",
		"REACTIVE_PRICE": "Precio energía reactiva",
		"CONSUMPTION": "Consumo",
		"ENERGY_TOLL_ADJUST": "Ajuste Peajes Energía",
		"OTHER_ENERGY_CONCEPTS": "Otros Conceptos Energía",
		"DISCOUNT": "Descuento",
		"REACTIVE_ENERGY_CONSUMPTION": "Consumo energía reactiva",
		"REACTIVE_ENERGY_COST": "Coste energía reactiva",
		"INVOICED_POWER": "Potencia facturada",
		"POWER_TERM_PRICE": "Precio Término de Potencia",
		"ENERGY_PRICE": "Precio Término de Energía",
		"POWER_TERM_REGULARIZATION": "Regularización Término de Potencia",
		"OVERPOWERING": "Excesos de Potencia",
		"SOCIAL_BOND": "Bono social",
		"power_surcharge_for_icp_lack": "Recargo en Potencia por falta de ICP",
		"other_power_concepts": "Otros conceptos de Potencia",
		"CUPS": "CUPS",
		"supply_address": "Dirección suministro",
		"rate_type": "Tarifa de acceso",
		"marketer": "Comercializadora",
		"CLIENT": "Cliente",
		"CONTRACTED": "Contratada",
		"equipment_rental": "Alquiler de equipos",
		"iberdrola_pymes": "Asistencia PYMES Iberdrola",
		"electrical_emergency_service": "Servicio Urgencias Eléctricas",
		"managemenet_support": "Soporte Gestión",
		"supply_quality_bond": "Abono por calidad de suministro",
		"penalty": "Penalización",
		"electricity_maintenance": "Mantenimiento electricidad",
		"professional_assistance_service": "Servicio Asistencia Profesionales",
		"eon_assistance": "Servicio Asistencia E.On",
		"other_complements": "Otros complementos",
		"INVOICE_STUDY": "Comparativa",
		"invoiceFromDate": "Fecha Inicio Período Facturación",
		"invoiceToDate": "Fecha Final Período Facturación",
		"WATIUM": "Watium",
		"DIFFERENCE": "Diferencia",
		"electricity_tax_coefficient": "Coeficiente impuesto de la electricidad",
		"electricity_tax": "Impuesto de la electricidad",
		"energy_term_cost": "Coste Término Energía",
		"energy_toll_adjust": "Ajuste Peajes Energía",
		"other_energy_concepts": "Otros conceptos Energía",
		"TOTAL_ENERGY_TERM": "Total Término Energía",
		"SUBTOTAL": "Subtotal: potencia + consumo + reactiva + impuesto el.",
		"taxable_iva": "Total - Base imponible IVA",
		"POWER_TERM_COST": "Coste Término Potencia",
		"total_power_costs": "Total Término Potencia",
		"ENERGY": "Energía",
		"REACTIVE_ENERGY": "Energía reactiva",
		"TOTAL_REACTIVE_ENERGY": "Total energía reactiva",
		"ELECTRIC_TAXABLE": "Base imponible eléctrica",
		"total_taxable_iva": "Base imponible IVA 21%",
		"last_invoice_total": "TOTAL DE ÚLTIMA FACTURA",
		"no_consumption_data": "No existen datos de consumo para este punto de suministro",
		"no_prices_data": "No existen datos de precios para esta tarifa",
		"initial_read_date": "Fecha lectura inicial",
		"final_read_date": "Fecha lectura final",
		"number_of_days": "Nº de dias",
		"maximeter_power_demand": "Potencia demanda maxímetro",
		"contracted_power": "Potencia contratada",
		"recommended_power": "Potencia recomendada",
		"invoiced_power_in_watium": "Potencia facturada en Watium",
		"actual": "Actual",
		"saving": "AHORRO",
		"EXP_COMMERCIAL_OFFER": "Exp. Comercial",
		"NIF/CIF": "NIF/CIF",
		"DATE": "Fecha",
		"SENT": "Enviado",
		"PENDING": "No enviado",
		"CANCELED": "Cancelado",
		"REJECTED": "Rechazado",
		"ACCEPTED": "Aceptado",
		"COMMERCIAL_PRICE_RATE": "Tarifas de Precios Comerciales",
		"NEW_COMMERCIAL_PRICE_RATE": "Nueva Tarifa de precios",
		"business_record": "Expediente comercial",
		"UPDATE_SUCCESS": "Informe actualizado correctamente",
		"UPDATE_ERROR": "Error al actualizar el informe",
		"new_rate_type": "Proponer nueva tarifa",
		"CONFIRM_DELETE": "Desea borrar el expediente comercial?",
		"ACTIONS": "Acciones",
		"CHANGE_STATUS": "Cambiar de estado",
		"NEW_STATUS": "Nuevo estado",
		"CHANGE_STATUS_OK": "Ofertas comerciales actualizados correctamente. ",
		"DOWNLOAD_RESUME": "Descargar resumen",
		"DOWNLOAD_DETAIL": "Descargar detalle",
		"Download PDF Task created": "Tarea de descarga puesta en cola",
		"Download generation Task error": "Error al crear la tarea de descarga",
		"ADD_CONCEPT": "Añadir concepto",
		"SAVING": "Ahorro",
		"ANNUAL_SAVING": "Ahorro anual",
		"RESULT": "Resultado",
		"POSITIVE": "Positivo",
		"NEGATIVE": "Negativo",
		"CONTACT_DATA": "Datos de Contacto",
		"NEW_CONTACT": "Nuevo Contacto",
		"CREATE": "Crear",
		"CREATE_CONTACT": "Crear Contacto",
		"CONTACT_CREATED_SUCESSFULLY": "Contacto creado con éxito",
		"CONTACT_UPDATED_SUCESSFULLY": "Contacto actualizado con éxito",
		"CONTACT_DELETED_SUCESSFULLY": "Contacto eliminado con éxito.",
		"INFORMATION": "Información",
		"WRITE_INFORMATION": "Escribe información acerca del contacto",
		"INVOICE_CONSUMPTION": "Consumos de la factura",
		"REPORT_DATA": "Datos del titular",
		"SUPPLY_DATA": "Datos del Punto de Suministro",
		"INVOICE_PERIOD": "Periodo de facturación",
		"COMPARATIVE_DATA": "Cabecera de la comparativa",
		"COMPARATIVE_REPORT": "Informe Comparativo",
		"COMPARATIVE_REPORTS": "Informe Comparativos",
		"NEW_COMPARATIVE_REPORT": "Nuevo Informe Comparativo",
		"INVOICE_PRICES": "Precios de factura",
		"OFFER_PRICES": "Precios de la oferta",
		"OFFER": "Oferta",
		"INVOICE": "Factura",
		"ITEM": "Concepto",
		"ACTION": "Acción",
		"DAYS": "Días",
		"TAX_TOTAL": "Total de IVA",
		"RECORD_CREATED_SUCESSFULLY": "Comparativa creada con éxito",
		"RECORD_DELETED_SUCESSFULLY": "Comparativa borrada con éxito",
		"RECORD_UPDATED_SUCESSFULLY": "Comparativa actualizada con éxito",
		"POWER_STUDY": "Estudio de potencia",
		"CREATE_COMPARATIVE_REPORT": "Crear informe comparativo",
		"NO_POWER_STUDY": "Sin estudio de potencia",
		"COMMERCIAL_COMMENTS": "Comentarios Agenda de visitas"
  }
}
