function adjustContract(UserInfo, filtersUtils, DateUtils) {
    return {
        restrict: 'AE',
        scope: {},
        templateUrl: require('../../templates/actions-dropdown-adjust-contract.html'),
        controller: function ($scope, $http, $rootScope, Alert, $filter, Task, INVOICES_TYPES) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var $translate = $filter('translate');

            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.listToUpdate = [];
            $scope.from_last_invoice = false;
            $scope.to_last_invoice = false;
            $scope.invoices_types = {
                R: 'Refactura',
                N: 'Normal',
                X: 'Cierre estimada'
            };
            $scope.adjust_invoice_type = 'X';

            $scope.hasPerm = true; // UserInfo.hasPerm('invoices.can_create_estimated_invoices');

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedItemsChange", function (e, selectedItemsChange) {
                $scope.listToUpdate = selectedItemsChange;
            });

            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/

            /***********************************************************************************************************
             * Scope Methods
             ***********************************************************************************************************/
            $scope.closeEstimatedPeriod = function () {
                var ids = filtersUtils.getItemsToDownloadToInt($scope.listToUpdate);
                var json = {
                    ids: ids,
                    adjust_invoice_type: $scope.adjust_invoice_type
                };

                if($scope.from_last_invoice){
                    json['from_last_invoice'] = $scope.from_last_invoice;
                }else if($scope.adjust_start_date){
                    let offset = $scope.adjust_start_date.getTimezoneOffset()
                    $scope.adjust_start_date = new Date($scope.adjust_start_date.getTime() - (offset*60*1000))

                    json['start_date'] = $scope.adjust_start_date.toISOString().split('T')[0];;
                }

                if($scope.adjust_finish_date){
                    let offset = $scope.adjust_finish_date.getTimezoneOffset()
                    $scope.adjust_finish_date = new Date($scope.adjust_finish_date.getTime() - (offset*60*1000))

                    json['finish_date'] = $scope.adjust_finish_date.toISOString().split('T')[0];;
                }

                var task = new Task();
                task.type = 'ECAD';
                task.params = json;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.Close estimated invoice Task Created'), true);
                }).catch(function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.Close estimated invoice Task Creation ERROR'), true);
                }).finally(function () {
                    $scope.flagRemitDate = false;
                    $scope.$parent.loading = false;
                });

                $scope.flagCloseDate = false;
            };

            $scope.confirmCloseDate = function () {
                $scope.flagCloseDate = true;
            };

            $scope.updateNo = function () {
                $scope.flagCloseDate = false;
            };
        }
    }
}

export {adjustContract}
