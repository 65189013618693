const CountryCodesMaster = [
    {code: 'QU', label: 'OTROS PAÍSES O TERRITORIOS NO RELACIONADOS'},
    {code: 'XB', label: 'BANCO CENTRAL EUROPEO'},
    {code: 'XU', label: 'INSTITUCIONES DE LA UNIÓN EUROPEA'},
    {code: 'XN', label: 'ORGANISMOS INTERNACIONALES DISTINTOS DE LAS INSTITUCIONES DE LA UNIÓN EUROPEA Y DEL BANCO CENTRAL EUROPEO'},
    {code: 'AF', label: 'Afganistán'},
    {code: 'AX', label: 'Åland, Islas'},
    {code: 'AL', label: 'Albania'},
    {code: 'DE', label: 'Alemania'},
    {code: 'AD', label: 'Andorra'},
    {code: 'AO', label: 'Angola'},
    {code: 'AI', label: 'Anguila'},
    {code: 'AQ', label: 'Antártida'},
    {code: 'AG', label: 'Antigua y Barbuda'},
    {code: 'SA', label: 'Arabia Saudita'},
    {code: 'DZ', label: 'Argelia'},
    {code: 'AR', label: 'Argentina'},
    {code: 'AM', label: 'Armenia'},
    {code: 'AW', label: 'Aruba'},
    {code: 'AU', label: 'Australia'},
    {code: 'AT', label: 'Austria'},
    {code: 'AZ', label: 'Azerbaiyán'},
    {code: 'BS', label: 'Bahamas (las)'},
    {code: 'BD', label: 'Bangladesh'},
    {code: 'BB', label: 'Barbados'},
    {code: 'BH', label: 'Bahrein'},
    {code: 'BE', label: 'Bélgica'},
    {code: 'BZ', label: 'Belice'},
    {code: 'BJ', label: 'Benin'},
    {code: 'BM', label: 'Bermudas'},
    {code: 'BY', label: 'Belarús'},
    {code: 'BO', label: 'Bolivia (Estado Plurinacional de)'},
    {code: 'BQ', label: 'Bonaire, San Eustaquio y Saba'},
    {code: 'BA', label: 'Bosnia y Herzegovina'},
    {code: 'BW', label: 'Botswana'},
    {code: 'BR', label: 'Brasil'},
    {code: 'BN', label: 'Brunei Darussalam'},
    {code: 'BG', label: 'Bulgaria'},
    {code: 'BF', label: 'Burkina Faso'},
    {code: 'BI', label: 'Burundi'},
    {code: 'BT', label: 'Bhután'},
    {code: 'CV', label: 'Cabo Verde'},
    {code: 'KH', label: 'Camboya'},
    {code: 'CM', label: 'Camerún'},
    {code: 'CA', label: 'Canadá'},
    {code: 'QA', label: 'Qatar'},
    {code: 'TD', label: 'Chad'},
    {code: 'CL', label: 'Chile'},
    {code: 'CN', label: 'China'},
    {code: 'CY', label: 'Chipre'},
    {code: 'CO', label: 'Colombia'},
    {code: 'KM', label: 'Comoras (las)'},
    {code: 'KP', label: 'Corea (la República Popular Democrática de)'},
    {code: 'KR', label: 'Corea (la República de)'},
    {code: 'CI', label: 'Côte d\'Ivoire'},
    {code: 'CR', label: 'Costa Rica'},
    {code: 'HR', label: 'Croacia'},
    {code: 'CU', label: 'Cuba'},
    {code: 'CW', label: 'Curaçao'},
    {code: 'DK', label: 'Dinamarca'},
    {code: 'DM', label: 'Dominica'},
    {code: 'EC', label: 'Ecuador'},
    {code: 'EG', label: 'Egipto'},
    {code: 'SV', label: 'El Salvador'},
    {code: 'AE', label: 'Emiratos Árabes Unidos (los)'},
    {code: 'ER', label: 'Eritrea'},
    {code: 'SK', label: 'Eslovaquia'},
    {code: 'SI', label: 'Eslovenia'},
    {code: 'ES', label: 'España'},
    {code: 'US', label: 'Estados Unidos de América (los)'},
    {code: 'EE', label: 'Estonia'},
    {code: 'ET', label: 'Etiopía'},
    {code: 'PH', label: 'Filipinas (las)'},
    {code: 'FI', label: 'Finlandia'},
    {code: 'FJ', label: 'Fiji'},
    {code: 'FR', label: 'Francia'},
    {code: 'GA', label: 'Gabón'},
    {code: 'GM', label: 'Gambia (la)'},
    {code: 'GE', label: 'Georgia'},
    {code: 'GH', label: 'Ghana'},
    {code: 'GI', label: 'Gibraltar'},
    {code: 'GD', label: 'Granada'},
    {code: 'GR', label: 'Grecia'},
    {code: 'GL', label: 'Groenlandia'},
    {code: 'GP', label: 'Guadeloupe'},
    {code: 'GU', label: 'Guam'},
    {code: 'GT', label: 'Guatemala'},
    {code: 'GF', label: 'Guayana Francesa'},
    {code: 'GG', label: 'Guernsey'},
    {code: 'GN', label: 'Guinea'},
    {code: 'GW', label: 'Guinea Bissau'},
    {code: 'GQ', label: 'Guinea Ecuatorial'},
    {code: 'GY', label: 'Guyana'},
    {code: 'HT', label: 'Haití'},
    {code: 'HN', label: 'Honduras'},
    {code: 'HK', label: 'Hong Kong'},
    {code: 'HU', label: 'Hungría'},
    {code: 'IN', label: 'India'},
    {code: 'ID', label: 'Indonesia'},
    {code: 'IQ', label: 'Iraq'},
    {code: 'IR', label: 'Irán (República Islámica de)'},
    {code: 'IE', label: 'Irlanda'},
    {code: 'BV', label: 'Bouvet, Isla'},
    {code: 'IM', label: 'Isla de Man'},
    {code: 'CX', label: 'Navidad, Isla de'},
    {code: 'IS', label: 'Islandia'},
    {code: 'KY', label: 'Caimán, (las) Islas'},
    {code: 'CC', label: 'Cocos / Keeling, (las) Islas'},
    {code: 'CK', label: 'Cook, (las) Islas'},
    {code: 'FO', label: 'Feroe, (las) Islas'},
    {code: 'GS', label: 'Georgia del Sur (la) y las Islas Sandwich del Sur'},
    {code: 'HM', label: 'Heard (Isla) e Islas McDonald'},
    {code: 'FK', label: 'Malvinas [Falkland], (las) Islas'},
    {code: 'MP', label: 'Marianas del Norte, (las) Islas'},
    {code: 'MH', label: 'Marshall, (las) Islas'},
    {code: 'PN', label: 'Pitcairn'},
    {code: 'SB', label: 'Salomón, Islas'},
    {code: 'TC', label: 'Turcas y Caicos, (las) Islas'},
    {code: 'UM', label: 'Islas Ultramarinas Menores de los Estados Unidos (las)'},
    {code: 'VG', label: 'Vírgenes británicas, Islas'},
    {code: 'VI', label: 'Vírgenes de los Estados Unidos, Islas'},
    {code: 'IL', label: 'Israel'},
    {code: 'IT', label: 'Italia'},
    {code: 'JM', label: 'Jamaica'},
    {code: 'JP', label: 'Japón'},
    {code: 'JE', label: 'Jersey'},
    {code: 'JO', label: 'Jordania'},
    {code: 'KZ', label: 'Kazajstán'},
    {code: 'KE', label: 'Kenya'},
    {code: 'KG', label: 'Kirguistán'},
    {code: 'KI', label: 'Kiribati'},
    {code: 'KW', label: 'Kuwait'},
    {code: 'LA', label: 'Lao, (la) República Democrática Popular'},
    {code: 'LS', label: 'Lesotho'},
    {code: 'LV', label: 'Letonia'},
    {code: 'LB', label: 'Líbano'},
    {code: 'LR', label: 'Liberia'},
    {code: 'LY', label: 'Libia'},
    {code: 'LI', label: 'Liechtenstein'},
    {code: 'LT', label: 'Lituania'},
    {code: 'LU', label: 'Luxemburgo'},
    {code: 'MO', label: 'Macao'},
    {code: 'MK', label: 'Macedonia (la ex República Yugoslava de)'},
    {code: 'MG', label: 'Madagascar'},
    {code: 'MY', label: 'Malasia'},
    {code: 'MW', label: 'Malawi'},
    {code: 'MV', label: 'Maldivas'},
    {code: 'ML', label: 'Malí'},
    {code: 'MT', label: 'Malta'},
    {code: 'MA', label: 'Marruecos'},
    {code: 'MQ', label: 'Martinique'},
    {code: 'MU', label: 'Mauricio'},
    {code: 'MR', label: 'Mauritania'},
    {code: 'YT', label: 'Mayotte'},
    {code: 'MX', label: 'México'},
    {code: 'FM', label: 'Micronesia (Estados Federados de)'},
    {code: 'MD', label: 'Moldova (la República de)'},
    {code: 'MC', label: 'Mónaco'},
    {code: 'MN', label: 'Mongolia'},
    {code: 'ME', label: 'Montenegro'},
    {code: 'MS', label: 'Montserrat'},
    {code: 'MZ', label: 'Mozambique'},
    {code: 'MM', label: 'Myanmar'},
    {code: 'NA', label: 'Namibia'},
    {code: 'NR', label: 'Nauru'},
    {code: 'NP', label: 'Nepal'},
    {code: 'NI', label: 'Nicaragua'},
    {code: 'NE', label: 'Níger (el)'},
    {code: 'NG', label: 'Nigeria'},
    {code: 'NU', label: 'Niue'},
    {code: 'NF', label: 'Norfolk, Isla'},
    {code: 'NO', label: 'Noruega'},
    {code: 'NC', label: 'Nueva Caledonia'},
    {code: 'NZ', label: 'Nueva Zelandia'},
    {code: 'OM', label: 'Omán'},
    {code: 'NL', label: 'Países Bajos (los)'},
    {code: 'PK', label: 'Pakistán'},
    {code: 'PW', label: 'Palau'},
    {code: 'PS', label: 'Palestina, Estado de'},
    {code: 'PA', label: 'Panamá'},
    {code: 'PG', label: 'Papua Nueva Guinea'},
    {code: 'PY', label: 'Paraguay'},
    {code: 'PE', label: 'Perú'},
    {code: 'PF', label: 'Polinesia Francesa'},
    {code: 'PL', label: 'Polonia'},
    {code: 'PT', label: 'Portugal'},
    {code: 'PR', label: 'Puerto Rico'},
    {code: 'GB', label: 'Reino Unido de Gran Bretaña e Irlanda del Norte (el)'},
    {code: 'EH', label: 'Sahara Occidental'},
    {code: 'CF', label: 'República Centroafricana (la)'},
    {code: 'CZ', label: 'Chequia'},
    {code: 'CG', label: 'Congo (el)'},
    {code: 'CD', label: 'Congo (la República Democrática del)'},
    {code: 'DO', label: 'Dominicana, (la) República'},
    {code: 'RE', label: 'Reunión'},
    {code: 'RW', label: 'Rwanda'},
    {code: 'RO', label: 'Rumania'},
    {code: 'RU', label: 'Rusia, (la) Federación de'},
    {code: 'WS', label: 'Samoa'},
    {code: 'AS', label: 'Samoa Americana'},
    {code: 'BL', label: 'Saint Barthélemy'},
    {code: 'KN', label: 'Saint Kitts y Nevis'},
    {code: 'SM', label: 'San Marino'},
    {code: 'MF', label: 'Saint Martin (parte francesa)'},
    {code: 'PM', label: 'San Pedro y Miquelón'},
    {code: 'VC', label: 'San Vicente y las Granadinas'},
    {code: 'SH', label: 'Santa Helena, Ascensión y Tristán de Acuña'},
    {code: 'LC', label: 'Santa Lucía'},
    {code: 'ST', label: 'Santo Tomé y Príncipe'},
    {code: 'SN', label: 'Senegal'},
    {code: 'RS', label: 'Serbia'},
    {code: 'SC', label: 'Seychelles'},
    {code: 'SL', label: 'Sierra leona'},
    {code: 'SG', label: 'Singapur'},
    {code: 'SX', label: 'Sint Maarten (parte neerlandesa)'},
    {code: 'SY', label: 'República Árabe Siria'},
    {code: 'SO', label: 'Somalia'},
    {code: 'LK', label: 'Sri Lanka'},
    {code: 'SZ', label: 'Swazilandia'},
    {code: 'ZA', label: 'Sudáfrica'},
    {code: 'SD', label: 'Sudán (el)'},
    {code: 'SS', label: 'Sudán del Sur'},
    {code: 'SE', label: 'Suecia'},
    {code: 'CH', label: 'Suiza'},
    {code: 'SR', label: 'Suriname'},
    {code: 'SJ', label: 'Svalbard y Jan Mayen'},
    {code: 'TH', label: 'Tailandia'},
    {code: 'TW', label: 'Taiwán (Provincia de China)'},
    {code: 'TZ', label: 'Tanzania, República Unida de'},
    {code: 'TJ', label: 'Tayikistán'},
    {code: 'IO', label: 'Territorio Británico del Océano Índico (el)'},
    {code: 'TF', label: 'Tierras Australes Francesas (las)'},
    {code: 'TL', label: 'Timor-Leste'},
    {code: 'TG', label: 'Togo'},
    {code: 'TK', label: 'Tokelau'},
    {code: 'TO', label: 'Tonga'},
    {code: 'TT', label: 'Trinidad y Tobago'},
    {code: 'TN', label: 'Túnez'},
    {code: 'TM', label: 'Turkmenistán'},
    {code: 'TR', label: 'Turquía'},
    {code: 'TV', label: 'Tuvalu'},
    {code: 'UA', label: 'Ucrania'},
    {code: 'UG', label: 'Uganda'},
    {code: 'UY', label: 'Uruguay'},
    {code: 'UZ', label: 'Uzbekistán'},
    {code: 'VU', label: 'Vanuatu'},
    {code: 'VA', label: 'Santa Sede (la)'},
    {code: 'VE', label: 'Venezuela (República Bolivariana de)'},
    {code: 'VN', label: 'Viet Nam'},
    {code: 'WF', label: 'Wallis y Futuna'},
    {code: 'YE', label: 'Yemen'},
    {code: 'DJ', label: 'Djibouti'},
    {code: 'ZM', label: 'Zambia'},
    {code: 'ZW', label: 'Zimbabwe'}
];


export {CountryCodesMaster}
