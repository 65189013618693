{
  "AGREEMENTS": {
    "TITLE": "Convenios",
    "SEARCH": "Buscar...",
    "STATE": "Provincia",
    "ANY": "Cualquiera",
    "ALL": "Todo",
    "CODE": "Código",
    "NIF/CIF": "NIF/CIF",
    "AGREEMENT_DATA": "Datos del convenio",
    "USER_MANAGEMENT": "Usuarios web",
    "FEES_RANGE": "Rangos de honorarios",
    "COMMENTS": "Comentarios",
    "FILES": "Archivos",
    "NAME": "Nombre",
    "DNI/CIF": "DNI/CIF",
    "EMAIL": "E-mail",
    "PHONE": "Teléfono",
    "MOBILE": "Móvil",
    "FAX": "Fax",
    "EXTERNAL_SYNCHRONIZATION_CODE": "Código de sincronización externo",
    "GENERATES_COMMISSIONS": "Genera comisiones",
    "YES": "Sí",
    "NO": "No",
    "AGREEMENTS_CONDITIONS": "Condiciones del convenio",
    "MINIMUM_ACTIVE_CONTRACTS": "Mínimo contratos activos",
    "MAXIMUM_DELAY_PAYMENT": "Máxima demora pago (días)",
    "ADDRESS_DATA": "Datos de dirección",
    "ADDRESS": "Dirección",
    "TOWN": "Población",
    "ZIP_CODE": "Código postal",
    "BILLING": "Datos de facturación",
    "BANK_ACCOUNT": "Cuenta bancaria",
    "IVA": "IVA",
    "IRPF": "IRPF",
    "LOADING": "Cargando...",
    "RATE": "Tarifa",
    "CALCULATION_BASED_ON": "Cálculo basado en",
    "FEE_ANNUAL": "Honorario (anual)",
    "POWER_TERM": "Término de potencia",
    "ENERGY_TERM": "Término de energía",
    "SAVE": "Guardar",
    "DELETE": "Eliminar",
    "ANNUAL_FEE": "Honorario anual",
    "EURO/YEAR": "€/año",
    "POSITIVE_NUMBER_ERROR": "Debes introducir un número positivo",
    "MINIMUM_ERROR": "El valor mínimo no puede ser mayor que el máximo",
    "ESTIMATED_ANNUAL_CONSUMPTION": "Cons. anual estimado",
    "MONTHLY_CONSUMPTION": "Cons. mensual",
    "ADD": "Añadir",
    "SELECT_RATE": "Elige tarifa",
    "SELECT_MODE": "Elige modo",
    "POW_MIN": "Pot. min.",
    "POW_MAX": "Pot. max.",
    "PROFESSIONALS_AREA_ACCESS": "Acceso zona de profesionales",
    "CUSTOMERS_AREA_ACCESS": "Acceso zona de clientes",
    "RELATED_AGREEMENTS": "Convenios relacionados",
    "SELECT": "Selecciona",
    "EMPTY_LIST": "Lista vacía",
    "SUCCESS_UPDATED": "Rango actualizado correctamente",
    "SYNCHRONIZATION_AUDINFOR": "Sincronización Audinfor",
    "STATUS": "Estado",
    "ACTIVE": "Activo",
    "INACTIVE": "Baja",
    "PENDING": "Pendiente",
    "AGREEMENT_CONFIGURATION": "Configuración del convenio",
    "AGREEMENT": "Convenio",
    "INACTIVE_COMMISSIONING_CONTRACTS": "Comisiona contratos inactivos",
    "INVALID_EMAIL": "El formato de email no es válido",
    "CODE_MANDATORY": "Debe incluir el Código",
    "INVALID_MIN_CONTRACTS": "El valor ha de ser un número entero",
    "INVALID_MAX_DELAY_PAYMENT": "El valor ha de ser un número entero",
    "INVALID_VAT_PERCENT": "El valor ha de ser un número entero",
    "INVALID_WITHHOLDING_PERCENT": "El valor ha de ser un número entero",
    "SUCCESS_AGREEMENT_UPDATED": "Convenio Actualizado con éxito",
    "ERROR_AGREEMENT_UPDATED": "Convenio no actualizado. Compruebe los errores.",
    "ERROR_INVALID_MAIL": "El formato del email no es válido",
    "NEW_AGREEMENT": "Nuevo Convenio",
    "AGREEMENT_CREATED_SUCCESSFULLY": "Convenio creado con éxito",
    "ERROR_AGREEMENT_CREATED": "Convenio no creado. Compruebe los errores.",
    "DOWNLOAD": "Descargar",
    "DOWNLOAD_CSV": "Descargar CSV",
    "DELETE_AGREEMENT": "¿Eliminar convenio?",
    "AGREEMENT_DELETED_SUCCESSFULLY": "Convenio eliminado con éxito",
    "VIEW": "Ver",
    "FEES": "Honorarios",
    "DATE": "Fecha",
    "COMMISSION": "Comisión",
    "COMMISSION_CONSUMPTION": "€ / kWh",
    "CONSUMPTION": "Consumo",
    "FROM": "Desde",
    "TO": "Hasta",
    "CONSUMPTION_BASED": "Basada en consumo",
    "EURO/Kw": "€/kWh",
    "AGREEMENT_SURNAME_1": "Apellido 1",
    "AGREEMENT_SURNAME_2": "Apellido 2",
    "AGREEMENT_NUMBER": "Número",
    "AGREEMENT_EXT": "Extensión",
    "RECEIVE_PAPER_INVOICES": "Recibir facturas en papel",
    "DEFAULT_BANK_ACCOUNT": "Cuenta de pago por defecto para contratos",
    "CONTACT_INFORMATION": "Información de contacto",
    "CONTACT_DATA": "Datos de contacto",
    "CONTACT_ADDRESS": "Dirección de contacto",
    "PRICE_DELETED": "Rango eliminado correctamente",
    "PRICE_CREATED": "Rango creado correctamente",
    "USER_ADDED": "Usuario añadido correctamente",
    "USER_DELETED": "Usuario eliminado correctamente",
    "RELATED_AGREEMENT_ADDED": "Convenio relacionado añadido correctamente",
    "RELATED_AGREEMENT_DELETED": "Convenio relacionado eliminado correctamente",
    "LOGS": "Logs",
    "OBSERVATIONS": "Observaciones",
    "STATUS_REQUIRED": "El estado es obligatorio.",
    "CONTRACT": "Contrato",
    "COMMERCIAL_COMMENTS": "Observaciones de Comercial",
    "FILTER_BY_CONTRACT": "Filtrar por contrato",
    "INVALID_CITY_VALUE": "La población no es válida",
    "Date range": "Fechas",
    "Without Filter": "No Filtrar",
    "Date from": "Desde",
    "Date to": "Hasta",
    "Activation date range": "Fecha de Activación",
    "Creation date range": "Fecha de Creación",
    "COPY_FROM_CONTACT": "Copiar desde contacto",
    "NOT_MANAGE_DATE": "Fecha no gestiona",
    "PAYMENT_NOTES": "Notas de pago",
    "RENEWAL_EMAIL": "Email para Renovaciones",
    "RENEWAL_EMPTY": "No hay emails con acceso web",
    "TYPE": "Tipo",
    "NORMAL": "Normal",
    "RENTAL": "Alquileres o colaborador",
    "TYPE_REQUIRED": "El tipo es obligatorio",
    "DEBTS_EMAIL": "Email para cobros",
    "POSITIVE_DAY_NUMBER_ERROR": "Debes introducir un número positivo entre el 1..31",
    "CSV_DOWNLOAD": "Descarga solicitada, por favor espera...",
    "CONFIRM_BULK_UPDATE": "¡Estos datos de actualizarán en todos convenios seleccionados!"
  }
}
