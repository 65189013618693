{
  "CUSTOMERS": {
    "TITLE": "Clients list",
    "STATE": "State",
    "STATE_MANDATORY": "State is mandatory",
    "ALL": "All",
    "CODE": "Code",
    "NAME": "Name",
    "NAME_MANDATORY": "Name is mandatory",
    "DNI/CIF": "DNI/CIF",
    "DNI/CIF_INVALID": "DNI/CIF is invalid",
    "DNI/CIF_MANDATORY": "DNI/CIF is mandatory",
    "EMAIL": "E-mail",
    "EMAIL_INVALID": "E-mail is invalid",
    "PHONE": "Phone",
    "MOBILE": "Mobile",
    "FAX": "Fax",
    "ADDRESS_DATA": "Address data",
    "ADDRESS": "Address",
    "ADDRESS_MANDATORY": "Address is mandatory",
    "TOWN": "Town",
    "TOWN_MANDATORY": "Town is mandatory",
    "ZIP_CODE": "Zip code",
    "ZIP_CODE_MANDATORY": "Zip code is mandatory",
    "ANY": "Any",
    "NIF/CIF": "NIF/CIF",
    "NO_CLIENT": "No. client",
    "CLIENT": "Client",
    "CUSTOMER_DATA": "Customer data",
    "NAME_SURNAME": "Full name",
    "CUSTOMER": "Customer Information",
    "COMMENTS": "Comments",
    "FILES": "Files",
    "CUSTOMERS_CONTRACTS": "Contracts",
    "TAX_ADDRESS": "Tax address",
    "CREATE": "Create",
    "UPDATE": "Update",
    "CUSTOMER_UPDATED": "Customer data updated successfully",
    "CUSTOMER_UPDATED_ERROR": "Failed to update customer data",
    "ELECTRONIC_INVOICE": "Electronic invoice",
    "ADMINISTRATIVE_CENTRE": "Administrative centre",
    "PLATFORM": "Platform",
    "ACCOUNTING_OFFICE": "Accounting Office",
    "MANAGEMENT_BODY": "Management Body",
    "TRANSACTED_UNIT": "Transacted Unit",
    "EXPIRATION_DAYS": "Invoice expiration days",
    "CONTRACT_NUM": "No. contract",
    "VIEW": "View",
    "LOGS": "Logs",
    "BANK_account-number": "Bank account number",
    "CUSTOMER_FULLNAME": "Name",
    "CUSTOMER_SURNAME_1": "Surname 1",
    "CUSTOMER_SURNAME_2": "Surname 2",
    "REQUIRED": "required",
    "UPDATE_CUSTOMER": "Update customer",
    "CONFIRM_UPDATE_CUSTOMER": "Now you must introduce a reason to update de customer.",
    "WEB_ACCESS": "Web access",
    "YES": "Yes",
    "NO": "No",
    "PASSPORT_ADVISE": "(*) To find/create a passport include 'P-' follow of passport number (with uppercase letters)",
    "INVALID_CITY_VALUE": "Invalid city value",
    "BUYER_PARTY": "Buyer Party",
    "SOCIAL_BONUS": "Social Bonus",
    "ADDITIONAL_INFO": "Additional Information",
    "OBSERVATIONS": "Observations",
    "TYPE": "Type",
    "TYPES": {
      "ZERO": "Normal",
      "ONE": "1. Individuals: invoices deferral",
      "TWO": "2. Freelances/Business: Contract modification",
      "THREE": "3. Freelances/Business: Contract suspension",
      "FOUR": "4. Freelances/Business: Invoice suspension",
      "FIVE": "5. Returning previous type COVID-19"
    },
    "DEPOSIT": "Deposit",
    "NONE": "None",
    "DEPOSIT_WARRANTY": "Deposit and Warranty",
    "WARRANTY": "Warranty",
    "SCORING": "Scoring",
    "SCORING_TYPE": "Scoring Type",
    "SCORING_LIMIT": "Scoring Limit",
    "DEPOSIT_DATE": "Deposit Date",
    "DEPOSIT_RETURN_DATE": "Deposit return Date",
    "SCORING_DATE": "Scoring date",
    "SCORING_DEPOSIT": "Scoring or Deposit",
    "NOT_INFO": "Not Information",
    "VALID": "Valid",
    "INVALID": "Invalid",
    "FORCED": "Forced",
    "DEPOSIT_MANDATORY": "Deposit is mandatory",
    "ACCOUNTING": "Accounting",
    "HAS_ACTIVE_CONTRACTS": "Has active contracts",
    "TIPO_DOCUMENTO": "DOC TYPE",
    "COUNTRY": "Country",
    "CONFIRM_BULK_UPDATE": "The change will be performed in the selected customers!"
  }
}
