{
  "CUSTOMERS": {
    "TITLE": "Lista de clientes",
    "STATE": "Provincia",
    "STATE_MANDATORY": "La provincia es obligatoria",
    "ALL": "Todo",
    "CODE": "Código",
    "NAME": "Nombre",
    "NAME_MANDATORY": "El nombre es obligatorio",
    "DNI/CIF": "DNI/CIF",
    "DNI/CIF_INVALID": "El DNI/CIF es incorrecto",
    "DNI/CIF_MANDATORY": "El DNI/CIF es obligatorio",
    "EMAIL": "E-mail",
    "EMAIL_INVALID": "El e-mail es incorrecto",
    "PHONE": "Teléfono",
    "MOBILE": "Móvil",
    "FAX": "Fax",
    "ADDRESS_DATA": "Datos de dirección",
    "ADDRESS": "Dirección",
    "ADDRESS_MANDATORY": "La dirección es olbigatoria",
    "TOWN": "Población",
    "TOWN_MANDATORY": "La población es obligatoria",
    "ZIP_CODE": "Código postal",
    "ZIP_CODE_MANDATORY": "El código postal es obligatorio",
    "ANY": "Cualquiera",
    "NIF/CIF": "NIF/CIF",
    "NO_CLIENT": "Nº cliente",
    "CLIENT": "Cliente",
    "CUSTOMER_DATA": "Datos del cliente",
    "NAME_SURNAME": "Nombre y apellidos",
    "CUSTOMER": "Datos del Cliente",
    "COMMENTS": "Comentarios",
    "FILES": "Archivos",
    "CUSTOMERS_CONTRACTS": "Contratos",
    "TAX_ADDRESS": "Dirección fiscal",
    "CREATE": "Crear",
    "UPDATE": "Actualizar",
    "CUSTOMER_UPDATED": "Datos de cliente actualizados correctamente",
    "CUSTOMER_UPDATED_ERROR": "Error al actualizar datos del cliente",
    "ELECTRONIC_INVOICE": "Factura electrónica",
    "ADMINISTRATIVE_CENTRE": "Centro administrativo",
    "PLATFORM": "Plataforma",
    "ACCOUNTING_OFFICE": "Oficina Contable",
    "MANAGEMENT_BODY": "Órgano Gestor",
    "TRANSACTED_UNIT": "Unidad Tramitadora",
    "EXPIRATION_DAYS": "Días vencimiento",
    "CONTRACT_NUM": "Nº Contracto",
    "VIEW": "Ver",
    "LOGS": "Logs",
    "BANK_account-number": "Número de cuenta bancaria",
    "CUSTOMER_FULLNAME": "Nombre",
    "CUSTOMER_SURNAME_1": "Apellido 1",
    "CUSTOMER_SURNAME_2": "Apellido 2",
    "REQUIRED": "obligatorio",
    "UPDATE_CUSTOMER": "Actualización de cliente",
    "CONFIRM_UPDATE_CUSTOMER": "A continuación debe introducir el motivo por el cual va a modificar el cliente.",
    "WEB_ACCESS": "Acceso a la Web",
    "YES": "Sí",
    "NO": "No",
    "PASSPORT_ADVISE": "(*) Para buscar/grabar un pasaporte se indicará 'P-' seguido del número de pasaporte (letras en mayúscula)",
    "INVALID_CITY_VALUE": "La población no es válida",
    "BUYER_PARTY": "Unidad Pagadora",
    "SOCIAL_BONUS": "Bono Social",
    "ADDITIONAL_INFO": "Información Adicional",
    "OBSERVATIONS": "Observaciones",
    "TYPE": "Tipo",
    "TYPES": {
      "ZERO": "Normal",
      "ONE": "1. Particulares: aplazamiento de facturas",
      "TWO": "2. Autónomos/Empresas: Modificación de contrato",
      "THREE": "3. Autónomos/Empresas: Supensión de contrato",
      "FOUR": "4. Autónomos/Empresas: Suspensión de facturas",
      "FIVE": "5. Retorno situación anterior COVID-19"
    },
    "DEPOSIT": "Fianza",
    "DEPOSIT_WARRANTY": "Fianza y Garantía",
    "WARRANTY": "Garantía",
    "NONE": "Ninguna",
    "SCORING": "Scoring",
    "SCORING_TYPE": "Tipo de Scoring",
    "SCORING_LIMIT": "Limite Scoring",
    "DEPOSIT_DATE": "Fecha de deposito",
    "DEPOSIT_RETURN_DATE": "Fecha de retorno de deposito",
    "SCORING_DEPOSIT": "Scoring o Fianza",
    "SCORING_DATE": "Fecha scoring",
    "NOT_INFO": "Sin Información",
    "VALID": "Válido",
    "INVALID": "No Válido",
    "FORCED": "Forzado",
    "DEPOSIT_MANDATORY": "La Fianza es obligatoria. Mínimo valor 0.",
    "ACCOUNTING": "Cuenta contable",
    "HAS_ACTIVE_CONTRACTS": "Tiene contratos activos",
    "TIPO_DOCUMENTO": "Documento",
    "COUNTRY": "País",
    "CONFIRM_BULK_UPDATE": "¡Estos datos de actualizarán en todos los clientes seleccionados!"
  }
}
