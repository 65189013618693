
// Controllers
import { CreateProgrammingUnitCtrl } from './controllers/CreateProgrammingUnitCtrl'
import { ProgrammingUnitsCtrl } from './controllers/ProgrammingUnitsCtrl'
import { ProgrammingUnitsDetailCtrl } from './controllers/ProgrammingUnitsDetailCtrl'
import { ProgrammingUnitsListCtrl } from './controllers/ProgrammingUnitsListCtrl'

// Directives
import { unlinkProgrammingUnitToSupplies } from './directives/unlinkProgrammingUnitToSupplies'
import { linkProgrammingUnitToSupplies } from './directives/linkProgrammingUnitToSupplies'

// Services
import { ProgrammingUnitAPI } from './services/ProgrammingUnitApi'

const programming_units = angular.module('programming_units', []);

programming_units.constant('ELECTRICAL_SUBSYSTEMS', [
    {code: 'PEN', label: 'Peninsular'},
    {code: 'BAL', label: 'Baleares'},
    {code: 'MEL', label: 'Melilla'},
    {code: 'CEU', label: 'Ceuta'}

]);

programming_units.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

programming_units
    .controller('CreateProgrammingUnitCtrl', CreateProgrammingUnitCtrl)
    .controller('ProgrammingUnitsCtrl', ProgrammingUnitsCtrl)
    .controller('ProgrammingUnitsDetailCtrl', ProgrammingUnitsDetailCtrl)
    .controller('ProgrammingUnitsListCtrl', ProgrammingUnitsListCtrl)
    .service('ProgrammingUnitAPI', ProgrammingUnitAPI)
    .directive('unlinkProgrammingUnitToSupplies', unlinkProgrammingUnitToSupplies)
    .directive('linkProgrammingUnitToSupplies', linkProgrammingUnitToSupplies)

export {programming_units}
