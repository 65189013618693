// const contractListFilters = function
function contractListFilters(SUPPLY_RATES){
    return {
        scope: {
            filters: '='
        },
        //templateUrl: require('../../static/contracts/templates/contracts-list-filters.html'),
        templateUrl: require('../../templates/contracts-list-filters.html'),
        controller: ['$scope', '$filter', 'ProductRatesMaster', 'AgreementsMaster', 'BrandsList', 'CONTRACT_STATUS', 'CONTRACT_SUB_STATUS', 'CONTRACT_MODE', 'CONTRACT_SELF_CONSUMPTION_MODE', '$routeParams', '$location', 'CONTRACT_INVOICING_GENERATION_MODE',
            function ($scope, $filter, ProductRatesMaster, AgreementsMaster, BrandsList, CONTRACT_STATUS, CONTRACT_SUB_STATUS, CONTRACT_MODE, CONTRACT_SELF_CONSUMPTION_MODE, $routeParams, $location, CONTRACT_INVOICING_GENERATION_MODE) {
                /***********************************************************************************************************
                 * Controller Attributes
                 ***********************************************************************************************************/
                var $translate = $filter('translate');

                /***********************************************************************************************************
                 * Scope Attributes
                 ***********************************************************************************************************/
                $scope.searchQuery = '';
                $scope.searchBy = 'search';
                $scope.searchByLabel = $translate('CONTRACTS.ALL');
                $scope.searchFilters = {
                    'idn': $translate('CONTRACTS.CONTRACT_NUM'),
                    'fullname': $translate('CONTRACTS.CONTRACT_FULLNAME'),
                    'cups': $translate('CONTRACTS.SUPPLY_CUPS'),
                    'vat_id': $translate('CONTRACTS.Vat id'),
                    'search': $translate('CONTRACTS.ALL')
                };
                $scope.searchBy = Object.keys($scope.searchFilters)[0];
                $scope.searchByLabel = $scope.searchFilters[$scope.searchBy];
                $scope.statuses = CONTRACT_STATUS;

                $scope.rate = SUPPLY_RATES;
                $scope.contracts_mode = CONTRACT_MODE;
                $scope.self_consumption_mode = CONTRACT_SELF_CONSUMPTION_MODE;

                $scope.estimated_types = [
                    {code: 0, label: $translate('CONTRACTS.NO')},
                    {code: 2, label: $translate('CONTRACTS.ESTIMATED_PROFILED')}
                ];
                
                $scope.ordering = {
                    "id": $translate('CONTRACTS.CONTRACT_NUM'),
                    "status": $translate('CONTRACTS.STATUS')
                };

                $scope.payment_methods = [
                    {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
                    {code: 'DBT', label: $translate('INVOICES.DEBIT')},
                    {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')},
                    {code: 'GBT', label: $translate('INVOICES.DEBIT_GBT')}
                ];

                $scope.invoice_generation_modes = CONTRACT_INVOICING_GENERATION_MODE;

                $scope.filters = {
                    search: "",
                    id: "",
                    status: null,
                    agreement: null,
                    state: null,
                    sub_status: null,
                    vat_id: "",
                    fullname: "",
                    rate: null,
                    partial_search: false,
                    invoicing_mode: "",
                    payment_method: "",
                    is_self_consumption: null,
                    invoice_generation_mode: null,
                    estimated: null
                }

                $scope.date_filters = {
                    product_date_range: null,
                    date_range_from: null,
                    date_range_to: null
                };

                $scope.filters_by_date = [
                    {value: "sent", description: $translate('CONTRACTS.Sent date range')},
                    {value: "app", description: $translate('CONTRACTS.App date range')},
                    {value: "expiration", description: $translate('CONTRACTS.Expiration date range')},
                    {value: "activation", description: $translate('CONTRACTS.Activation date range')}
                ];

                /***********************************************************************************************************
                 * Scope Watchers
                 ***********************************************************************************************************/
                $scope.$watch('searchQuery', function (newVal, oldVal) {
                    $scope.filters[$scope.searchBy] = newVal;
                });

                $scope.$watchCollection('filters', function (newVal, oldVal) {
                    if (oldVal.status != newVal.status) {
                        $scope.filters.sub_status = null;
                        if (newVal.status !== null) {
                            $scope.sub_status = CONTRACT_SUB_STATUS[newVal.status]
                        } else {
                            $scope.sub_status = [];
                            angular.forEach(CONTRACT_SUB_STATUS, function (value, key) {
                                for (var i = 0; i < value.length; i++) {
                                    var found = false;
                                    for (var j = 0; j < $scope.sub_status.length; j++) {
                                        if ($scope.sub_status[j].code === value[i].code) {
                                            found = true;
                                        }
                                    }
                                    if (!found) {
                                        $scope.sub_status.push(value[i]);
                                    }
                                }
                            });
                        }
                    }

                    angular.forEach(newVal, function (value, key) {
                        if(value){
                            $location.search(key, value)
                        }else{
                            $location.search(key, null)
                        }
                    })
                });

                $scope.$watchCollection('date_filters', function (newVal, oldVal) {
                    if (oldVal.product_date_range !== null) {
                        var sel_option = oldVal.product_date_range.value;
                        if (oldVal.date_range_from !== null) {
                            $scope.filters[sel_option + "_date_from"] = null;
                        }
                        if (oldVal.date_range_to !== null) {
                            $scope.filters[sel_option + "_date_to"] = null;
                        }
                    }
                    if (newVal.product_date_range !== null) {
                        var sel_option = newVal.product_date_range.value;
                        if (newVal.date_range_from !== null) {
                            var from_date = newVal.date_range_from;
                            $scope.filters[sel_option + "_date_from"] = from_date;
                        }
                        if (newVal.date_range_to !== null) {
                            var to_date = newVal.date_range_to;
                            $scope.filters[sel_option + "_date_to"] = to_date;
                        }
                    }

                    angular.forEach(newVal, function (value, key) {
                        if((key == 'product_date_range') && value && value.value){
                            $location.search(key, value.value)
                        }else if(value){
                            $location.search(key, value)
                        }else{
                            $location.search(key, null)
                        }
                    })
                });

                $scope.$watch('noContractsForAgreement', function (newValue, oldValue) {
                    if (newValue) {
                        $scope.filters['agreement'] = null;
                    }
                });

                /***********************************************************************************************************
                 * Controller Methods
                 ***********************************************************************************************************/

                /***********************************************************************************************************
                 * Scope Methods
                 ***********************************************************************************************************/
                $scope.setSearchBy = function (val) {
                    $scope.filters[$scope.searchBy] = '';
                    $scope.searchBy = val;
                    $scope.searchByLabel = $scope.searchFilters[val];
                    $scope.filters[val] = $scope.searchQuery;
                };

                /***********************************************************************************************************
                 * Controller Initialization
                 ***********************************************************************************************************/
                AgreementsMaster.loadAgreements().then(function (response) {
                    $scope.agreements = response;
                });

                ProductRatesMaster.loadProductRatesNotForce().then(function (response) {
                    $scope.product_rates = response;
                });

                ProductRatesMaster.loadProductRatesForce().then(function (response) {
                    $scope.product_rates_not_in_force = response;
                });

                $scope.sub_status = [];
                angular.forEach(CONTRACT_SUB_STATUS, function (value, key) {
                    for (var i = 0; i < value.length; i++) {
                        var found = false;
                        for (var j = 0; j < $scope.sub_status.length; j++) {
                            if ($scope.sub_status[j].code === value[i].code) {
                                found = true;
                            }
                        }
                        if (!found) {
                            $scope.sub_status.push(value[i]);
                        }
                    }
                });

                angular.forEach($routeParams, function (value, key) {
                    if(key in $scope.date_filters){
                        let date_filter_obj = null

                        for(const index in $scope.filters_by_date){
                            if($scope.filters_by_date[index].value == value){
                                date_filter_obj = $scope.filters_by_date[index]
                                break
                            }
                        }

                        if(date_filter_obj){
                            $scope.date_filters[key] = date_filter_obj
                        }else{
                            $scope.date_filters[key] = value
                        }
                    }else if(key in $scope.searchFilters){
                        $scope.setSearchBy(key)
                        $scope.searchQuery = value
                    }
                    else{
                        $scope.filters[key] = value
                    }
                })

                BrandsList.all().then(function (response) {
                    if(response)
                        $scope.brands = [{
                            'code': 'null',
                            'name': $translate('BRANDS.NONE')
                        }].concat(response)
                });

            }
        ]
    };
}

export {contractListFilters}
